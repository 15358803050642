export const DEFAULT_FACEBOOK_ID = "396960148186452"
export const DEFAULT_GOOGLE_ID =
  "747693767696-af249unniqjhetk2u6dv1huinm03vspr.apps.googleusercontent.com"

export const LEGAL_TEXT_LABEL = {
  PRIVACY_POLICY: "basic.legalConsentIntroPrivacyPolicy",
  TERMS_OF_USE_P1: "basic.legalConsentIntroTermsOfUse",
  TERMS_OF_USE_P2: "basic.legalConsent",
}

export const COUNTRIES = {
  UNITED_STATE: "us",
  UNITED_KINGDOM: "uk",
  AUSTRIA: "at",
  BELGIUM: "be",
  FRANCE: "fr",
  GERMANY: "de",
  POLAND: "pl",
  SPAIN: "es",
  SWEDEN: "se",
  NETHERLANDS: "nl",
  CZECH: "cz",
}

export type TLegalText = {
  label: string
  content: string | null
  version: number
}

export type TCenter = {
  center: string
  country: string
  center_name: string
  center_short_name: string
  fid_id: number
  url_terms_of_use: string
  url_privacy_policy: string
  url_logo: string
  facebook_id: string
  google_id: string
  is_westfield: boolean
  default_language: string
  languages_available: string[]
  legalText: TLegalText[]
  multistepRegistration?: boolean
}
