import React, { FC, MouseEvent, useRef } from "react"
import { useTranslation } from "react-i18next"
import { ButtonGroupElement } from "../../../utils/type/uiComponentType"

type Props = {
  elements: ButtonGroupElement[]
  label?: string
  required?: boolean
  outlined?: boolean
  selectedElement: string | undefined
  onClick: (value: string) => void
}

const ButtonGroup: FC<Props> = ({
  elements,
  label,
  required,
  selectedElement,
  onClick,
}: Props) => {
  const { t } = useTranslation()
  const ref = useRef<any>(null)

  const onButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onClick(event.currentTarget.value)
  }

  return (
    <div>
      <p className="mb-2 text-left">
        {label} {required && "*"}
      </p>
      <div
        className="flex rounded w-full flex divide-x divide-urw-gray-light"
        role="group"
      >
        {elements.map((item) => (
          <button
            ref={ref}
            key={item.key}
            type="button"
            value={item.key}
            onClick={onButtonClick}
            className={`flex-grow px-3 py-1 outline-none text-urw-gray ${
              selectedElement && selectedElement === item.key ? "font-bold" : ""
            } focus:outline-none whitespace-nowrap`}
          >
            {t(item.label)}
          </button>
        ))}
      </div>
    </div>
  )
}
export default ButtonGroup
