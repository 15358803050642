import React, { FC, useState } from "react"
import {
  GoogleLogin,
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from "react-google-login"
import { useTranslation } from "react-i18next"
import { ReactComponent as GoogleSVG } from "../../../assets/search.svg"
import {
  TEmailLogin,
  TFacebookLogin,
  TGoogleLogin,
} from "../../../utils/type/LoginType"
import {
  ParametersStore,
  TParamsStore,
} from "../../../utils/store/ParametersStore"
import { TSocialInformations } from "../../../utils/type/RegisterType"
import { DEFAULT_GOOGLE_ID } from "../../../utils/constantes/Centers"
import { toast } from "react-toastify"
import { useStore } from "effector-react"

type TGoogleButton = {
  type: "register" | "login"
  callbackLogin?: (
    body: TEmailLogin | TGoogleLogin | TFacebookLogin,
    errorAction?: () => void
  ) => void
  callbackRegister?: (informations?: TSocialInformations) => void
}

const GoogleButton: FC<TGoogleButton> = ({
  type,
  callbackRegister,
  callbackLogin,
}: TGoogleButton) => {
  const { t } = useTranslation()
  const queryParams: TParamsStore = useStore(ParametersStore)
  const [buttonDisable, setButtonDisable] = useState<boolean>(false)
  const INITIALIZATION_ERROR = "idpiframe_initialization_failed"

  const getInformationsFromGoogle = (
    response: GoogleLoginResponse | GoogleLoginResponseOffline
  ): TSocialInformations | undefined => {
    if ("accessToken" in response && response.accessToken) {
      return {
        firstname: response.profileObj.givenName,
        lastname: response.profileObj.familyName,
        email: response.profileObj.email,
        origin: "google",
        googleId: response.googleId,
        tokenRegister: response.tokenId,
      }
    }
    return undefined
  }

  const login = (
    response: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => {
    const googleInformations = getInformationsFromGoogle(response)
    const googleObj: TGoogleLogin = {
      email: googleInformations?.email || "",
      googleId: googleInformations?.googleId || "",
      centerId: queryParams.center,
      origin: "google",
    }
    callbackLogin && callbackLogin(googleObj)
  }

  const register = (
    response: GoogleLoginResponse | GoogleLoginResponseOffline
  ): void => {
    const googleInformations = getInformationsFromGoogle(response)
    callbackRegister && callbackRegister(googleInformations)
  }

  function handleLoginFailure(event: any) {
    if (event.error === INITIALIZATION_ERROR) {
      setButtonDisable(true)
    }
  }

  return (
    <div className="g-signin2" data-onsuccess="onSignIn">
      <GoogleLogin
        clientId={DEFAULT_GOOGLE_ID}
        render={(renderProps) => (
          <button
            onClick={() => {
              buttonDisable
                ? toast.error(t("error.googleNotAllowed"))
                : renderProps.onClick()
            }}
            disabled={renderProps.disabled}
            className="text-xxs leading-none md:text-xs flex bg-white justify-center h-full w-full items-center p-2 border border-urw-gray-light text-gray-500 rounded focus:outline-none"
          >
            <GoogleSVG className="w-4 h-4 mr-1 md:w-6 md:h-6 md:mr-2" />
            {t("buttons.googleCta")}
          </button>
        )}
        onSuccess={type === "register" ? register : login}
        onFailure={(event) => handleLoginFailure(event)}
        cookiePolicy={"single_host_origin"}
        responseType="code,token"
      />
    </div>
  )
}

export default GoogleButton
