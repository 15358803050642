import { createEvent, createStore } from "effector"

export type TParamsStore = {
  center?: number | string
  redirect_uri?: string
  client_id?: string
  response_type?: string
  scope?: string
  state?: string
  nonce?: string
  consent?: string
  lang?: string
}

export const ParamsStoreInitial: TParamsStore = {
  center: undefined,
  redirect_uri: undefined,
  client_id: undefined,
  response_type: undefined,
  scope: undefined,
  state: undefined,
  nonce: undefined,
  consent: undefined,
  lang: undefined,
}

export const setParamsInfo = createEvent<TParamsStore>("SET_CENTER_INFO")
export const setCenterId = createEvent<number | string>("SET_CENTER_ID")
export const setLanguage = createEvent<string>("SET_LANGUAGE")
export const clearParamsStore = createEvent("RESET_STORE")

export const ParametersStore = createStore<TParamsStore>(ParamsStoreInitial)
  .on(setParamsInfo, (state: TParamsStore, payload: TParamsStore) => {
    return payload
  })
  .on(setCenterId, (state: TParamsStore, payload: string | number) => {
    return { ...state, center: payload }
  })
  .on(setLanguage, (state: TParamsStore, payload: string) => {
    return { ...state, lang: payload }
  })
  .reset(clearParamsStore)
