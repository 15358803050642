import { TParamsStore } from "../store/ParametersStore"

export const generateStringQueryParams = (
  paramsStore: TParamsStore,
  excludeKey?: string[]
): string => {
  let queryParams = ""

  Object.entries(paramsStore).forEach(([key, value], index) => {
    if (value !== null) {
      if (key !== "center") {
        if (!excludeKey || !excludeKey.includes(key)) {
          queryParams += key + "=" + value
          if (index !== Object.entries(paramsStore).length - 1) {
            queryParams += "&"
          }
        }
      } else {
        // center should be always in first position because &cent is transform in ¢
        queryParams = "?center=" + value + "&" + queryParams
      }
    }
  })

  return encodeURI(queryParams)
}

export const generateObjectQueryParams = (
  paramsStore: TParamsStore,
  excludeKey?: string[]
): any => {
  let queryParams = {}
  Object.entries(paramsStore).forEach(([key, value], index) => {
    if (value !== null) {
      if (!excludeKey || !excludeKey.includes(key)) {
        queryParams = {
          ...queryParams,
          [key.replace(/_([a-z])/g, function (g) {
            return g[1].toUpperCase()
          })]: value,
        }
      }
    }
  })

  return queryParams
}
