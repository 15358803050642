import React, { FC, useState } from "react"

type ToggleProps = {
  label?: string
  onChange: (isSelected: boolean) => void
}

const Toggle: FC<ToggleProps> = (props) => {
  const { label, onChange } = props
  const [isSelected, setSelectedValue] = useState<boolean>(false)

  return (
    <div className="flex items-center ">
      <div className="relative flex w-8 m-2 items-center">
        <input
          type="checkbox"
          name="toggle"
          id="toggle"
          onClick={() => {
            setSelectedValue((prev) => !prev)
            onChange(!isSelected)
          }}
          className={`absolute  w-4 h-4 rounded-full appearance-none cursor-pointer ${
            isSelected ? "transform translate-x-full bg-urw-red" : "bg-white"
          }`}
        />
        <label
          htmlFor="toggle"
          className={`block overflow-hidden h-4 w-full rounded-full  cursor-pointer ${
            isSelected ? "bg-urw-black" : "bg-urw-gray-light"
          }`}
        />
      </div>
      <p>{label}</p>
    </div>
  )
}

export default Toggle
