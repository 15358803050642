import React, { FC, useEffect } from "react"
import FormInput from "../uiComponents/uiForms/FormInput"
import Button from "../uiComponents/buttons/Button"
import { Controller, FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { PATHS } from "../../utils/constantes/PathRoutes"
import {
  TEmailLogin,
  TFacebookLogin,
  TGoogleLogin,
} from "../../utils/type/LoginType"
import Link from "../uiComponents/buttons/CustomLink"
import { CenterStore } from "../../utils/store/CenterStore"
import { useStore } from "effector-react"
import { ParametersStore } from "../../utils/store/ParametersStore"
import { generateStringQueryParams } from "../../utils/hooks/useQueryParams"
import Cookies from "universal-cookie"
import { COOKIE_NAMES } from "../../utils/constantes/Cookies"

interface TInputLogin {
  email: string
  password: string
  mandatory: boolean
  [x: string]: any
}

const schemaValidatorLogin = yup.object().shape({
  email: yup
    .string()
    .required("error.emailRequired")
    .email("error.emailInvalid")
    .trim(),
  password: yup.string().required("error.passwordRequired"),
})

type TEmailPasswordFormProps = {
  callback: (
    body: TEmailLogin | TGoogleLogin | TFacebookLogin,
    errorAction?: () => void
  ) => void
}

const EmailPasswordForm: FC<TEmailPasswordFormProps> = ({ callback }) => {
  const { t } = useTranslation()
  const center = useStore(CenterStore)
  const params = useStore(ParametersStore)
  const email = new Cookies().get(COOKIE_NAMES.USER_INFORMATION)?.email
  const methods = useForm<TInputLogin>({
    resolver: yupResolver(schemaValidatorLogin),
    mode: "onBlur",
  })

  const onSubmit = (data: TInputLogin) => {
    const postObj: TEmailLogin = {
      email: data.email,
      password: data.password,
      centerId: center.fid_id,
      origin: "email",
    }
    callback(postObj)
  }

  useEffect(() => {
    methods.setValue("email", email!)
  }, [location])

  return (
    <FormProvider {...methods}>
      <p className="text-xl mb-6">{t("login.formTitle")}</p>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className="space-y-6 w-full text-sm"
      >
        <div className="grid gap-x-16 gap-y-4 items-end">
          <Controller
            render={() => (
              <FormInput
                label={t("forms.email")}
                type="email"
                name="email"
                mandatory
              />
            )}
            name="email"
          />
          <div>
            <Controller
              render={() => (
                <FormInput
                  label={t("forms.password")}
                  type="password"
                  name="password"
                  mandatory
                />
              )}
              name="password"
            />
          </div>
          <Link
            label="buttons.forgotPassword"
            to={{
              pathname: PATHS.FORGOT_PASSWORD,
              search: generateStringQueryParams(params),
            }}
          />
        </div>
        <Button type="submit" styleCss="ACTION">
          {t("buttons.loginCta")}
        </Button>
      </form>
    </FormProvider>
  )
}
export default EmailPasswordForm
