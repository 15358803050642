export const API = {
  AUTH: {
    AUTHORIZE: "/authorize",
    LOGIN: "/login",
    REGISTER: "/register",
    REGISTER_STEP1: "/register/step1",
    REGISTER_STEP2: "/register/step2",
    REGISTER_STEP3: "/register/step3",
    RESEND_CODE: "/register/step1/resendCode",
    FORGOT_PASSWORD: "/password/forgot",
    RESET_PASSWORD: "/password/reset",
    MAGIC_LINK: "/magiclink",
    CLIENT_INFO: "/client/",
  },
  ENRICHMENT: "/enrichments",
  CENTER: "/center/",
}
