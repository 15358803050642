import React, { FC } from "react"

type TContainerLayout = {
  large?: boolean
  className?: string
}
const ContainerLayout: FC<TContainerLayout> = ({
  className,
  large,
  children,
}) => {
  return (
    <div
      className={`${
        large ? "lg:w-2/3 2xl:w-1/2" : "md:w-2/3 lg:w-2/5 xl: 2xl:w-2/5"
      } ${className} flex items-center bg-white bg-opacity-60 p-6 rounded shadow`}
    >
      {children}
    </div>
  )
}
export default ContainerLayout
