import React from "react"
import "./App.css"
import { ToastContainer, Zoom } from "react-toastify"
import { BrowserRouter as Router } from "react-router-dom"
import CustomSwitch from "./components/routes/CustomSwitch"
import { SelectLanguage } from "./utils/functions/selectLanguage"
import { useStore } from "effector-react"
import { LoadingStore } from "./utils/store/LoadingStore"
import LoaderComponent from "./components/uiComponents/LoaderComponent"
import ScrollToTop from "./components/uiComponents/ScrollToTop"

const App: React.FC = () => {
  SelectLanguage()
  const sizeToast = () => {
    if (window.innerWidth < 768) {
      return "100%"
    } else {
      return "50%"
    }
  }

  const storedLoading = useStore(LoadingStore)

  return (
    <div className="App">
      <ToastContainer
        style={{ width: sizeToast() }}
        position="top-center"
        autoClose={4000}
        transition={Zoom}
        hideProgressBar
        newestOnTop
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="flex-auto min-h-screen">
        <Router>
          {storedLoading > 0 && <LoaderComponent />}
          <ScrollToTop />
          <CustomSwitch />
        </Router>
      </div>
    </div>
  )
}

export default App
