import React, { FC, useEffect, useState } from "react"
import MainTemplate from "../components/uiComponents/layout/MainLayout"
import ContainerLayout from "../components/uiComponents/layout/ContainerLayout"
import { FaUser } from "react-icons/fa"
import Button from "../components/uiComponents/buttons/Button"
import { AxiosResponse } from "axios"
import { authService } from "../services/authService"
import { ParametersStore } from "../utils/store/ParametersStore"
import { useStore } from "effector-react"
import { PATHS } from "../utils/constantes/PathRoutes"
import { generateStringQueryParams } from "../utils/hooks/useQueryParams"
import { useHistory } from "react-router-dom"
import { decodeUrl } from "../utils/functions/fieldFunctions"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"

type TScopeValue = {
  email: boolean
  lastname: boolean
  firstname: boolean
}

const ScopeConsent: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [clientName, setClientName] = useState<string | null>(null)
  const paramsStore = useStore(ParametersStore)
  const [scope, setScope] = useState<TScopeValue>({
    email: false,
    firstname: false,
    lastname: false,
  })
  const urlRedirect = "https://id.westfield.com"

  const readScopeFromQueryParams = () => {
    const tabQueryParamsScope = paramsStore?.scope?.split(",")

    if (tabQueryParamsScope) {
      tabQueryParamsScope.map((scopeValue: string) => {
        switch (scopeValue) {
          case "profile":
            setScope((prev) => ({
              ...prev,
              email: true,
              firstname: true,
              lastname: true,
            }))
            break
          case "email":
            setScope((prev) => ({
              ...prev,
              email: true,
            }))
            break
          case "firstname":
            setScope((prev) => ({
              ...prev,
              firstname: true,
            }))
            break
          case "lastname":
            setScope((prev) => ({
              ...prev,
              lastname: true,
            }))
            break
        }
      })
    }
  }

  useEffect(() => {
    readScopeFromQueryParams()
    authService
      .clientInformation(paramsStore.client_id!)
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          setClientName(response.data.name)
        }
      })
      .catch(() => {
        toast.error(t("error.server"))
      })
  }, [paramsStore])

  const redirectToAuth = () => {
    authService
      .authorize({ ...paramsStore, consent: "true" })
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          if (
            response.data.redirectUrl &&
            !response.data.redirectUrl.includes("/undefined")
          ) {
            window.location.href = decodeUrl(response.data.redirectUrl)
          } else {
            history.push({
              pathname: PATHS.LOGIN,
              search: generateStringQueryParams(paramsStore),
            })
          }
        } else {
          history.push({
            pathname: PATHS.LOGIN,
            search: generateStringQueryParams(paramsStore),
          })
        }
      })
      .catch(() => {
        history.push({
          pathname: PATHS.LOGIN,
          search: generateStringQueryParams(paramsStore),
        })
      })
  }

  const redirectToClient = () => {
    if (paramsStore && paramsStore.redirect_uri) {
      window.location.href =
        decodeUrl(paramsStore.redirect_uri) + "?error=authorization_denied"
    }
  }

  return (
    <MainTemplate className="space-y-6">
      <ContainerLayout className="flex flex-col items-center space-y-6">
        <div>
          <span className="font-bold">{clientName} </span>
          <span className="text-left">{t("scope.title")}</span>
        </div>
        <div className="w-2/3 flex flex-col items-center divide-y-2">
          {scope.email && (
            <div className="w-full flex items-center ">
              <div className="ml-10 flex my-3 items-center space-x-3 w-2/3">
                <FaUser className="cursor-pointer text-urw-black" />
                <p>{t("scope.fields.email")}</p>
              </div>
            </div>
          )}
          {scope.firstname && (
            <div className="w-full flex items-center">
              <div className="ml-10 flex my-3  items-center space-x-3 w-2/3">
                <FaUser className="cursor-pointer text-urw-black" />
                <p>{t("scope.fields.firstname")}</p>
              </div>
            </div>
          )}
          {scope.lastname && (
            <div className="w-full flex items-center">
              <div className="ml-10 flex my-3 items-center space-x-3 w-2/3">
                <FaUser className="cursor-pointer text-urw-black" />
                <p>{t("scope.fields.lastname")}</p>
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col xl:flex-row items-center space-y-4 xl:space-y-0 xl:space-x-6">
          <Button
            type="button"
            styleCss="NEUTRAL"
            onClick={() => {
              redirectToClient()
            }}
          >
            {t("buttons.cancel")}
          </Button>
          <Button
            type="button"
            styleCss="ACTION"
            onClick={() => {
              redirectToAuth()
            }}
          >
            {t("buttons.authorize")}
          </Button>
        </div>
        <div>
          <p className="text-xs"> {t("scope.redirectTo")}</p>
          <p className="font-bold text-xs">{urlRedirect}</p>
        </div>
      </ContainerLayout>
    </MainTemplate>
  )
}
export default ScopeConsent
