import React, { FC } from "react"
import MainTemplate from "../components/uiComponents/layout/MainLayout"
import ContainerLayout from "../components/uiComponents/layout/ContainerLayout"
import { useTranslation } from "react-i18next"

const UnAuthorized: FC = () => {
  const { t } = useTranslation()
  return (
    <MainTemplate>
      <ContainerLayout className="flex-col">
        {t("error.missingParameters")}
      </ContainerLayout>
    </MainTemplate>
  )
}
export default UnAuthorized
