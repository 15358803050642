import React, { FC } from "react"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import Input, { InputProps } from "../inputs/Input"

type FormInputProps = InputProps & {
  label?: string
  error?: string
  propsInput?: any
  large?: boolean
  name: string
  mandatory?: boolean
}

const FormInput: FC<FormInputProps> = ({ label, name, mandatory, ...rest }) => {
  const { formState, register } = useFormContext()
  const { t } = useTranslation()

  return (
    <div className="flex flex-col text-left w-full">
      {label && (
        <p className="mb-2">
          {label} {mandatory ? "*" : ""}
        </p>
      )}
      <div
        className={`flex items-center p-2 rounded w-full focus:outline-none focus:shadow-md ${
          rest.readOnly ? "bg-gray-100" : "border border-gray-300"
        }`}
      >
        <Input {...rest} {...register(name)} />
      </div>
      <p
        className={`my-1 h-1 ${
          formState.errors[name]?.message
            ? "p-1 pl-2 bg-urw-red rounded bg-opacity-80 h-full text-white"
            : ""
        }`}
      >
        {t(formState.errors[name]?.message)}
      </p>
    </div>
  )
}
export default FormInput
