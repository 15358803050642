import React, { FC } from "react"
import { FormProvider, useForm } from "react-hook-form"
import InputText from "../components/uiComponents/uiForms/FormInput"
import Button from "../components/uiComponents/buttons/Button"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { useTranslation } from "react-i18next"
import { PARAMS, PATHS, routeAllowed } from "../utils/constantes/PathRoutes"
import { toast } from "react-toastify"
import { authService } from "../services/authService"
import {
  useGetValueSearchUrl,
  useParseUrl,
} from "../utils/hooks/useUrlFunctions"
import {
  TConnectionResponseWS,
  TErrorResponse,
  TResetPasswordRequest,
} from "../utils/type/WebServiceType"
import MainTemplate from "../components/uiComponents/layout/MainLayout"
import ContainerLayout from "../components/uiComponents/layout/ContainerLayout"
import { AxiosError, AxiosResponse } from "axios"
import { generateStringQueryParams } from "../utils/hooks/useQueryParams"
import { useHistory } from "react-router-dom"
import { useStore } from "effector-react"
import { ParametersStore } from "../utils/store/ParametersStore"
import { useSetAllCookieUser } from "../utils/hooks/useCookie"

interface INewPasswordForm {
  password: string
  confirmPassword: string
}

const schemaValidatorNewPassword = yup.object().shape({
  password: yup.string().required("error.passwordRequired").min(6).max(30),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "error.passwordNotMatched")
    .required("error.passwordNotMatched"),
})

const ResetPassword: FC = () => {
  useParseUrl()
  const history = useHistory()
  const { t } = useTranslation()
  const paramsStore = useStore(ParametersStore)
  const customerId = useGetValueSearchUrl().get(PARAMS.CUSTOMER_ID) || undefined
  const confirmationCode =
    useGetValueSearchUrl().get(PARAMS.CONFIRMATION_CODE) ||
    useGetValueSearchUrl().get(PARAMS.TOKEN) ||
    undefined
  const pasId = useGetValueSearchUrl().get(PARAMS.PAS_ID) || undefined
  const methods = useForm<INewPasswordForm>({
    resolver: yupResolver(schemaValidatorNewPassword),
    mode: "onBlur",
  })

  const onSubmit = (data: INewPasswordForm) => {
    if ((!customerId && !pasId) || !confirmationCode) {
      toast.error(t("error.server"))
      return
    }
    const postBody: TResetPasswordRequest = {
      centerId: paramsStore.center!,
      customerId,
      confirmationCode,
      pasId,
      password: data.password,
    }

    authService
      .resetPassword(postBody)
      .then((response: AxiosResponse<TConnectionResponseWS>) => {
        if (
          routeAllowed(PATHS.ENRICHMENT, paramsStore.client_id) &&
          response.data.firstConnection
        ) {
          useSetAllCookieUser({
            customerId: response.data.customerId,
            email: response.data.email,
            centerId: response.data.centerId,
          })
          history.push({
            pathname: PATHS.ENRICHMENT,
            search: generateStringQueryParams(paramsStore),
          })
        } else {
          history.push({
            pathname: PATHS.AUTH,
            search: generateStringQueryParams(paramsStore),
          })
        }
      })
      .catch((error: AxiosError<TErrorResponse>) => {
        switch (error.response?.data?.error?.code) {
          case "E40903": // if it is the same password
            toast.error(t("error.oldPassword"))
            break
          case "E40211": // if expired code
            toast.error(t("error.codeExpired"))
            break
          default:
            toast.error(t("error.server"))
        }
      })
  }

  return (
    <MainTemplate>
      <ContainerLayout className="flex-col">
        <div className="p-4 space-y-1 items-center mb-2">
          <p className="text-3xl">{t("newPassword.title")}</p>
          <p>{t("newPassword.description")}</p>
        </div>
        <div className="flex justify-center ">
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(onSubmit)}
              className="space-y-3"
            >
              <div className="grid gap-x-16 gap-y-6 items-end mb-4">
                <InputText
                  type="password"
                  name="password"
                  label={t("forms.password")}
                  mandatory
                />
                <InputText
                  name="confirmPassword"
                  label={t("forms.confirmPassword")}
                  type="password"
                  mandatory
                />
              </div>
              <Button type="submit" styleCss="ACTION">
                {t("buttons.validate")}
              </Button>
            </form>
          </FormProvider>
        </div>
      </ContainerLayout>
    </MainTemplate>
  )
}
export default ResetPassword
