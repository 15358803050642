import React, { FC } from "react"
import { useStore } from "effector-react"
import { CenterStore } from "../utils/store/CenterStore"
import { useTranslation } from "react-i18next"
import { ButtonGroupElement } from "../utils/type/uiComponentType"
import ButtonGroup from "../components/uiComponents/buttons/ButtonGroup"
import { generateStringQueryParams } from "../utils/hooks/useQueryParams"
import { ParametersStore, setLanguage } from "../utils/store/ParametersStore"
import { useHistory } from "react-router-dom"

const Footer: FC = () => {
  const { t } = useTranslation()
  const centerInfo = useStore(CenterStore)
  const paramsStore = useStore(ParametersStore)
  const history = useHistory()

  const generateLanguageButton = (): ButtonGroupElement[] => {
    const languagesAvailable: ButtonGroupElement[] = []

    centerInfo.languages_available.map((language) => {
      languagesAvailable.push({
        label: t(`languages.${language}`).toUpperCase(),
        key: language,
      })
    })

    return languagesAvailable
  }

  return (
    <footer className="mb-4 flex justify-center">
      <ButtonGroup
        elements={generateLanguageButton()}
        selectedElement={
          paramsStore.lang ? paramsStore.lang : centerInfo.default_language
        }
        onClick={(key: string) => {
          setLanguage(key)
          history.push({
            search:
              generateStringQueryParams(paramsStore, ["lang"]) + "&lang=" + key,
          })
        }}
      />
    </footer>
  )
}
export default Footer
