import React, { FC, MouseEvent, useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useFormContext } from "react-hook-form"
import { ButtonGroupElement } from "../../../utils/type/uiComponentType"

type Props = {
  elements: ButtonGroupElement[]
  name: string
  label?: string
  required?: boolean
}

const FormButtonGroup: FC<Props> = ({
  elements,
  name,
  label,
  required,
}: Props) => {
  const { t } = useTranslation()
  const { formState, setValue, watch } = useFormContext()
  const values = watch()
  const ref = useRef<any>(null)

  const styleBorderFirstElement = "border-r-0 rounded-l-lg"
  const styleBorderLastElement = "border-l-0 rounded-r-lg"

  const onButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    setValue(name, event.currentTarget.value)
  }

  useEffect(() => {
    if (formState.errors[name] && ref?.current) {
      ref.current!.focus()
    }
  }, [formState.errors])

  return (
    <div className="w-full ">
      <p className="mb-2 text-left">
        {label} {required && "*"}
      </p>
      <div className="flex rounded w-full flex" role="group">
        {elements.map((item, index) => (
          <button
            ref={ref}
            key={item.key}
            type="button"
            value={item.key}
            onClick={onButtonClick}
            className={`bg-white flex-grow hover:bg-urw-gray-light hover:text-white border border-urw-gray-light px-3 py-2 outline-none ${
              values[name] === item.key
                ? "bg-urw-black text-white"
                : "text-urw-gray "
            } focus:outline-none whitespace-nowrap ${
              index === 0
                ? styleBorderFirstElement
                : index === elements.length - 1
                ? styleBorderLastElement
                : ""
            }`}
          >
            {t(item.label)}
          </button>
        ))}
      </div>
      {formState.errors[name] && (
        <p className="text-white my-1 h-1 p-1 bg-urw-red rounded bg-opacity-80 h-full">
          {t(formState.errors[name].message)}
        </p>
      )}
    </div>
  )
}
export default FormButtonGroup
