import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import FormInput from "../uiComponents/uiForms/FormInput"
import FormButtonGroup from "../uiComponents/uiForms/FormButtonGroup"
import { genderTab } from "../../utils/constantes/RegisterValues"
import { Controller, useFormContext } from "react-hook-form"
import DatePicker from "../uiComponents/inputs/DatePicker"

type TRegisterFormProps = {
  hasSocialInformation: boolean
  isMultistepRegistration: boolean
}

const RegisterForm: FC<TRegisterFormProps> = ({
  hasSocialInformation = false,
  isMultistepRegistration = false,
}: TRegisterFormProps) => {
  const { t } = useTranslation()
  const { setValue } = useFormContext()

  return (
    <div>
      <div className="grid gap-x-16 gap-y-1 md:gap-y-6 md:grid-cols-2 md:mb-6 items-start mb-3">
        <Controller
          render={() => (
            <FormButtonGroup
              name="gender"
              elements={genderTab}
              label={t("forms.salutations")}
              required
            />
          )}
          name="gender"
        />
      </div>
      <div className="grid gap-x-16 gap-y-1 md:gap-y-6 md:grid-cols-2 md:mb-6 items-start">
        <Controller
          render={() => (
            <FormInput
              label={t("forms.firstname")}
              name="firstname"
              mandatory
            />
          )}
          name="firstname"
        />
        <Controller
          render={() => (
            <FormInput label={t("forms.lastname")} name="lastname" mandatory />
          )}
          name="lastname"
        />
        {!isMultistepRegistration && (
          <Controller
            render={() => (
              <FormInput
                label={t("forms.email")}
                name="email"
                type="email"
                mandatory
              />
            )}
            name="email"
          />
        )}
        {!hasSocialInformation && !isMultistepRegistration && (
          <Controller
            render={() => (
              <div>
                <FormInput
                  type="password"
                  name="password"
                  label={t("forms.password")}
                  mandatory
                />
                <p className="text-xs text-left">
                  {t("register.passwordAlert")}
                </p>
              </div>
            )}
            name="password"
          />
        )}
      </div>
      <div className="grid gap-x-16 gap-y-1 md:gap-y-6 md:grid-cols-2">
        <div>
          <DatePicker
            label={t("forms.birthday")}
            onChange={(value: string) => {
              setValue("birthdate", value)
            }}
          />
          <p className="text-xs text-left">{t("register.ageAlert")}</p>
        </div>
      </div>
    </div>
  )
}

export default RegisterForm
