import { ButtonGroupElement } from "../type/uiComponentType"

export const genderTab: ButtonGroupElement[] = [
  {
    label: "common.male",
    key: "M",
  },
  {
    label: "common.female",
    key: "F",
  },
  {
    label: "common.noGender",
    key: "U",
  },
]
