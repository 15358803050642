import React from "react"
import { FaSpinner } from "react-icons/fa"

export const LoaderComponent: React.FC = () => {
  return (
    <div>
      <div className="fixed z-50 inset-0 ">
        <div className="flex items-center justify-center h-screen transition-opacity">
          <div className="absolute inset-0 bg-black opacity-30" />
          <FaSpinner className="animate-spin-slow w-20 h-20 mx-2 text-white" />
        </div>
      </div>
    </div>
  )
}

export default LoaderComponent
