import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { Link, LinkProps } from "react-router-dom"

type TCustomLinkProps = LinkProps &
  React.RefAttributes<HTMLAnchorElement> & {
    label: string
  }

const CustomLink: FC<TCustomLinkProps> = ({ label, ...rest }) => {
  const { t } = useTranslation()
  return (
    <Link className="text-sm underline" {...rest}>
      {t(label)}
    </Link>
  )
}

export default CustomLink
