import { useTranslation } from "react-i18next"
import { useStore } from "effector-react"
import { CenterStore } from "../store/CenterStore"
import { useEffect } from "react"
import { ParametersStore } from "../store/ParametersStore"

export const SelectLanguage = (): void => {
  const { i18n } = useTranslation()
  const center = useStore(CenterStore)
  const paramsStore = useStore(ParametersStore)

  useEffect(() => {
    if (paramsStore.lang) {
      i18n.changeLanguage(paramsStore.lang)
    } else if (center?.default_language) {
      i18n.changeLanguage(center?.default_language)
    } else {
      i18n.changeLanguage("en-US")
    }
  }, [center, paramsStore])
}
