import { createEvent, createStore } from "effector"

export const setNumberOfActiveCalls = createEvent<number>(
  "SET_NUMBER_OF_ACTIVE_CALLS"
)
export const addActiveCalls = createEvent<number | undefined>(
  "ADD_ACTIVE_CALLS"
)
export const subtractActiveCalls = createEvent<number | undefined>(
  "SUBTRACT_ACTIVE_CALLS"
)

export const LoadingStore = createStore<number>(0)
  .on(setNumberOfActiveCalls, (state: number, payload: number) => {
    return payload
  })
  .on(addActiveCalls, (state: number, payload = 1) => {
    return state + payload
  })
  .on(subtractActiveCalls, (state: number, payload = 1) => {
    return state - payload
  })
