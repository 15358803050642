import axios, { AxiosError, AxiosResponse } from "axios"
import {
  addActiveCalls,
  subtractActiveCalls,
} from "../utils/store/LoadingStore"

// Define your api url from any source.
// Pulling from your .env file when on the server or from localhost when locally
const BASE_URL = "/oauth"

const request = async <T>(
  path: string,
  method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE",
  body?: Record<string, unknown>,
  headers?: Record<string, string>,
  params?: Record<string, unknown>
): Promise<AxiosResponse<T>> => {
  addActiveCalls()
  return await axios
    .request<T>({
      url: path.startsWith("https://") ? path : `${BASE_URL}${path}`,
      method: method || (body ? "POST" : "GET"),
      headers,
      data: body ? JSON.stringify(body) : undefined,
      params,
      timeout: 30000,
      withCredentials: true,
    })
    .then((res: AxiosResponse<T>) => res)
    .catch((res: AxiosError) => {
      throw res
    })
    .finally(subtractActiveCalls)
}

export const execute = async <T>(
  path: string,
  method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE",
  body?: Record<string, unknown>,
  headers: Record<string, string> = {},
  params?: Record<string, unknown>
): Promise<AxiosResponse<T>> => {
  const ipHeaders = {
    "content-type": "application/json",
    Accept: "application/json",
    ...headers,
  }

  return request<T>(path, method, body, ipHeaders, params)
}
