import { FC } from "react"
import React from "react"
import ContainerLayout from "../components/uiComponents/layout/ContainerLayout"
import MainTemplate from "../components/uiComponents/layout/MainLayout"
import { useTranslation } from "react-i18next"

const LimitReached: FC = () => {
  const { t } = useTranslation()
  return (
    <MainTemplate>
      <ContainerLayout className="flex-col">
        {t("error.limitReached")}
      </ContainerLayout>
    </MainTemplate>
  )
}
export default LimitReached
