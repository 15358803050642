import React, { FC, useState } from "react"
import { useTranslation } from "react-i18next"
import { useStore } from "effector-react"
import { CenterStore } from "../../utils/store/CenterStore"

type TChip = {
  label: string
  isSelected: boolean
  onChange: (newValue: boolean) => void
}

const Chip: FC<TChip> = ({ label, isSelected = false, onChange }: TChip) => {
  const { t } = useTranslation()
  const centerInfo = useStore(CenterStore)
  const cssIsSelected = "border border-urw-black bg-urw-black text-white"
  const cssNotSelected = "border border-gray-300 bg-white text-urw-black"
  const [isSelectedLocal, setIsSelected] = useState(isSelected)

  return (
    <div
      className={`${
        isSelectedLocal ? cssIsSelected : cssNotSelected
      } py-2 px-6 m-3 rounded cursor-pointer betterhover:hover:text-white ${
        centerInfo.is_westfield
          ? "betterhover:hover:border-urw-red betterhover:hover:bg-urw-red"
          : "betterhover:hover:border-no-westfield-primary betterhover:hover:bg-no-westfield-primary"
      }`}
      onClick={() => {
        onChange(!isSelectedLocal)
        setIsSelected((prev) => !prev)
      }}
    >
      <p>{t(label)}</p>
    </div>
  )
}
export default Chip
