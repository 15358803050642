import React, {
  DetailedHTMLProps,
  FC,
  ForwardedRef,
  forwardRef,
  ForwardRefRenderFunction,
  InputHTMLAttributes,
  useState,
} from "react"
import { FaEye, FaEyeSlash } from "react-icons/fa"

export type InputProps = Omit<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  "ref"
>

const InputComponent: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  props,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const { type, ...rest } = props
  const [inputType, setInputType] = useState(type)

  const onIconClick = () =>
    setInputType((prev) => (prev === "password" ? "text" : "password"))

  return (
    <>
      <input
        ref={ref}
        name={rest.name}
        className="focus:outline-none w-full bg-transparent appearance-none text-base cursor-pointer-none text-urw-gray"
        type={inputType}
        {...rest}
      />
      {type === "password" && inputType === "password" && (
        <FaEye
          className="cursor-pointer text-urw-black"
          onClick={onIconClick}
        />
      )}
      {type === "password" && inputType === "text" && (
        <FaEyeSlash
          className="cursor-pointer text-urw-black"
          onClick={onIconClick}
        />
      )}
    </>
  )
}

const Input: FC<InputProps> = forwardRef<HTMLInputElement, InputProps>(
  InputComponent
)

export default Input
