import React, { FC } from "react"
import { FaEnvelopeOpenText } from "react-icons/fa"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import MainTemplate from "../components/uiComponents/layout/MainLayout"
import ContainerLayout from "../components/uiComponents/layout/ContainerLayout"

type TLocation = {
  email: string
}
const MagicLink: FC = () => {
  const { t } = useTranslation()
  const location = useLocation<TLocation>()
  const email: string = location.state?.email || "undefined"

  return (
    <MainTemplate>
      <ContainerLayout className="flex flex-col items-center space-y-4">
        <div className="flex items-center">
          <FaEnvelopeOpenText className="mr-4 text-4xl" />
          <h1 className=" font-light">{t("magicLink.title")}</h1>
        </div>
        <p className=" font-light">
          {t("magicLink.description", { email: email })}
        </p>
      </ContainerLayout>
    </MainTemplate>
  )
}
export default MagicLink
