import React, { FC } from "react"
import Button from "../uiComponents/buttons/Button"
import Link from "../uiComponents/buttons/CustomLink"
import { PATHS } from "../../utils/constantes/PathRoutes"
import { generateStringQueryParams } from "../../utils/hooks/useQueryParams"
import { useTranslation } from "react-i18next"
import {
  ParametersStore,
  TParamsStore,
} from "../../utils/store/ParametersStore"
import { useStore } from "effector-react"
import { useFormContext } from "react-hook-form"

type TFooterRegisterProps = {
  acceptTerms?: boolean
}
const FooterRegister: FC<TFooterRegisterProps> = ({ acceptTerms = true }) => {
  const { t } = useTranslation()
  const queryParams: TParamsStore = useStore(ParametersStore)
  const { getValues } = useFormContext()

  return (
    <div className="flex flex-col space-y-3 items-center">
      <Button
        type="submit"
        disabled={acceptTerms && !getValues().termsOfUse}
        styleCss="ACTION"
      >
        {t("buttons.registerCta")}
      </Button>
      <Link
        label="buttons.alreadyAccount"
        to={{
          pathname: PATHS.LOGIN,
          search: generateStringQueryParams(queryParams),
        }}
      />
    </div>
  )
}
export default FooterRegister
