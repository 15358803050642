import React, { FC } from "react"
import LogoWestfield from "../assets/logo_westfield.svg"
import { useStore } from "effector-react"
import { CenterStore } from "../utils/store/CenterStore"

const Header: FC = () => {
  const centerInfo = useStore(CenterStore)

  return (
    <div
      className={`flex justify-center w-full p-4 bg-background-header ${
        centerInfo.is_westfield ? "bg-black" : "shadow-md"
      }`}
    >
      {centerInfo.url_logo ? (
        <img src={centerInfo.url_logo} width="130" alt="Logo westfield" />
      ) : (
        <img src={LogoWestfield} width="130" alt="Logo westfield" />
      )}
    </div>
  )
}

export default Header
