import React, { FC } from "react"
import Header from "../../../views/Header"
import Footer from "../../../views/Footer"

type TMainTemplate = {
  large?: boolean
  className?: string
}
const MainTemplate: FC<TMainTemplate> = ({ className, children }) => {
  return (
    <div
      className={`min-h-screen flex flex-col md:overflow-x-hidden bg-left bg-no-repeat bg-cover bg-fixed tablet_portrait:bg-center justify-between`}
    >
      <Header />
      <div
        className={`mt-10 p-6 flex flex-col items-center w-full text-urw-black ${className}`}
      >
        {children}
      </div>
      <Footer />
    </div>
  )
}
export default MainTemplate
