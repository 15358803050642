import { execute } from "../api/api"
import { API } from "../utils/constantes/ApiRoutes"
import {
  TEmailLogin,
  TFacebookLogin,
  TGoogleLogin,
} from "../utils/type/LoginType"
import {
  THeaderRegisterRequest,
  TRegisterRequest,
  TRegisterStep1Request,
  TRegisterStep2Request,
  TResendCodeMultistepRequest,
} from "../utils/type/RegisterType"
import {
  TClientInformationResponse,
  TConnectionResponseWS,
  TForgotPasswordRequest,
  TMagicLinkResponse,
  TRegisterResponseWS,
  TRegisterStepResponseWS,
  TResetPasswordRequest,
} from "../utils/type/WebServiceType"
import { AxiosResponse } from "axios"
import { TParamsStore } from "../utils/store/ParametersStore"

const authorize = (params: TParamsStore): Promise<AxiosResponse> => {
  return execute(API.AUTH.AUTHORIZE, "GET", {}, {}, params)
}

const postLogin = (
  body: TEmailLogin | TGoogleLogin | TFacebookLogin
): Promise<AxiosResponse<TConnectionResponseWS>> => {
  return execute(API.AUTH.LOGIN, "POST", body)
}

const getMagicLink = (
  token: string
): Promise<AxiosResponse<TMagicLinkResponse>> => {
  return execute(API.AUTH.MAGIC_LINK, "GET", {}, {}, { token })
}

const postRegister = (
  body: TRegisterRequest,
  headers: THeaderRegisterRequest,
  clientId?: string,
  isMultistep?: boolean
): Promise<AxiosResponse<TRegisterResponseWS>> => {
  return execute(
    isMultistep ? API.AUTH.REGISTER_STEP3 : API.AUTH.REGISTER,
    "POST",
    body,
    headers,
    { client_id: clientId }
  )
}

const postRegisterStep1 = (
  body: TRegisterStep1Request,
  clientId?: string
): Promise<AxiosResponse<TRegisterStepResponseWS>> => {
  return execute(
    API.AUTH.REGISTER_STEP1,
    "POST",
    body,
    {},
    { client_id: clientId }
  )
}

const postRegisterStep2 = (
  body: TRegisterStep2Request,
  clientId?: string
): Promise<AxiosResponse<TRegisterStepResponseWS>> => {
  return execute(
    API.AUTH.REGISTER_STEP2,
    "POST",
    body,
    {},
    { client_id: clientId }
  )
}

const resendCode = (
  body: TResendCodeMultistepRequest,
  clientId?: string
): Promise<AxiosResponse<TRegisterStepResponseWS>> => {
  return execute(
    API.AUTH.RESEND_CODE,
    "POST",
    body,
    {},
    { client_id: clientId }
  )
}

const postForgotPassword = (body: TForgotPasswordRequest): Promise<any> => {
  return execute(API.AUTH.FORGOT_PASSWORD, "POST", body)
}

const resetPassword = (
  body: TResetPasswordRequest
): Promise<AxiosResponse<TConnectionResponseWS>> => {
  return execute(API.AUTH.RESET_PASSWORD, "POST", body)
}

const clientInformation = (
  clientID: string
): Promise<AxiosResponse<TClientInformationResponse>> => {
  return execute(API.AUTH.CLIENT_INFO + clientID, "GET")
}

export const authService = {
  authorize,
  postLogin,
  getMagicLink,
  postRegister,
  postRegisterStep1,
  postRegisterStep2,
  postForgotPassword,
  resendCode,
  resetPassword,
  clientInformation,
}
