import React, { useRef, useState } from "react"
import { TFunction } from "i18next"
import { useTranslation } from "react-i18next"
import { useOutsideAlerter } from "../../../utils/hooks/useOutsideAlerter"
import { TOptionItems } from "../uiForms/FormSelector"
import { FaAd, FaChevronDown } from "react-icons/all"

type OptionProps = {
  t: TFunction
  option: TOptionItems
  onClick: (event: any) => void
  selected: boolean
  lastElement?: boolean
}
const Option: React.FC<OptionProps> = ({
  option,
  t,
  onClick,
  selected,
  lastElement,
}: OptionProps) => {
  return (
    <button
      type="button"
      value={option.key}
      key={option.key}
      className={`cursor-pointer w-full ${
        !lastElement ? "border-gray-100  border-b" : ""
      } rounded-t hover:bg-teal-100 focus:outline-none`}
      onClick={onClick}
    >
      <div
        className={`flex w-full p-2 hover:bg-gray-100 ${
          selected ? "bg-gray-100" : ""
        }`}
      >
        <div className="w-full items-center flex text-left">
          <div className="mx-2 leading-6 w-3/4" key={option.key}>
            {t(option.label)}
          </div>
          {selected && <FaAd />}
        </div>
      </div>
    </button>
  )
}

type SelectProps = {
  onChange: (value: any) => void
  meta?: any
  options: TOptionItems[]
}

const Selector: React.FC<SelectProps> = ({
  onChange,
  meta,
  options,
}: SelectProps) => {
  const { t } = useTranslation()
  const [openSelector, setOpenSelector] = useState(false)
  const [selectedValue, setSelectedValue] = useState<TOptionItems>()
  const refInput = useRef(null)
  const refDropdown = useRef(null)

  const handleOpenSelector = () => {
    setOpenSelector((prev) => !prev)
  }

  useOutsideAlerter(refInput, setOpenSelector, "mousedown", refDropdown)

  return (
    <div className="relative w-full">
      <div
        className={`h-full p-2 rounded flex items-center border bg-white text-urw-gray ${
          meta && meta.touched && meta.error
            ? "border-red-400"
            : "border-urw-gray-light "
        }`}
        onClick={handleOpenSelector}
        ref={refInput}
      >
        <div className="w-full truncate">
          {selectedValue ? selectedValue.label : ""}
        </div>
        <div className="flex justify-end">
          <FaChevronDown />
        </div>
      </div>
      {openSelector && (
        <div
          className="absolute rounded bg-white shadow-sm w-full py-2 px-2 max-h-48 text-left overflow-y-scroll z-50 mt-2 text-urw-gray"
          ref={refDropdown}
        >
          {options.map((element, index) => {
            return (
              <Option
                key={element.key + index.toString()}
                t={t}
                option={element}
                onClick={(event) => {
                  onChange(event)
                  const selectedValue = options.find(
                    (option) =>
                      option.key.toString() ===
                      event.currentTarget.value.toString()
                  )
                  setSelectedValue(selectedValue)
                  handleOpenSelector()
                }}
                selected={element.key.toString() === selectedValue?.toString()}
                lastElement={index === options.length - 1}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}

export default Selector
