import Login from "../../views/Login"
import Register from "../../views/Register"
import { TRoute } from "../type/RouteType"
import Enrichment from "../../views/Enrichment"
import ForgotPassword from "../../views/ForgotPassword"
import ResetPassword from "../../views/ResetPassword"
import MagicLink from "../../views/MagicLink"
import Auth from "../../views/Auth"
import Simulator from "../../views/Simulator"
import LimitReached from "../../views/LimitReached"
import AuthMagicLink from "../../views/AuthMagicLink"
import ScopeConsent from "../../views/ScopeConsent"
import EmailValidationStep1 from "../../views/emailValidation/EmailValidationStep1"
import EmailValidationStep2 from "../../views/emailValidation/EmailValidationStep2"

export const PATHS = {
  HOME: "/",
  AUTH: "/auth",
  LOGIN: "/login",
  REGISTER: {
    MAIN: "/register",
    STEP1: "/register/step1",
    STEP2: "/register/step2",
  },
  ENRICHMENT: "/enrichment",
  FORGOT_PASSWORD: "/password/forgot",
  NEW_PASSWORD: "/password/reset",
  MAGIC_LINK: {
    MAIN: "/emailsent/magiclink",
    AUTH: "/magiclink",
  },
  TESTING: "/simulator",
  LIMIT_REACHED: "/limitreached",
  CONSENT: "/consent",
}

export const PARAMS = {
  CLIENT_ID: "client_id",
  CENTER: "center",
  COUNTRY_CODE: "country",
  URL_REDIRECTION: "redirect_uri",
  CUSTOMER_ID: "customer_id",
  CONFIRMATION_CODE: "confirmationCode",
  PAS_ID: "person_id",
  TOKEN: "token",
}

export const MAESTRO_ID = process.env.REACT_APP_MAESTRO_ID || ""
export const CARDIWEB_ID = process.env.REACT_APP_CARDIWEB_ID || ""

export const routes: TRoute[] = [
  {
    name: "LOGIN",
    path: PATHS.LOGIN,
    component: Login,
  },
  {
    name: "REGISTER_EMAIL_VALIDATION_STEP1",
    path: PATHS.REGISTER.STEP1,
    component: EmailValidationStep1,
  },
  {
    name: "REGISTER_EMAIL_VALIDATION_STEP2",
    path: PATHS.REGISTER.STEP2,
    component: EmailValidationStep2,
  },
  {
    name: "REGISTER",
    path: PATHS.REGISTER.MAIN,
    component: Register,
  },
  {
    name: "ENRICHMENT",
    path: PATHS.ENRICHMENT,
    roles: [MAESTRO_ID, CARDIWEB_ID],
    component: Enrichment,
    withoutCenter: true,
  },
  {
    name: "FORGOT_PASSWORD",
    path: PATHS.FORGOT_PASSWORD,
    component: ForgotPassword,
  },
  {
    name: "NEW_PASSWORD",
    path: PATHS.NEW_PASSWORD,
    component: ResetPassword,
  },
  {
    name: "MAGIC_LINK",
    path: PATHS.MAGIC_LINK.MAIN,
    component: MagicLink,
  },
  {
    name: "AUTH_MAGIC_LINK",
    path: PATHS.MAGIC_LINK.AUTH,
    component: AuthMagicLink,
    withoutCenter: true,
  },
  {
    name: "AUTH",
    path: PATHS.AUTH,
    component: Auth,
    withoutCenter: true,
  },
  {
    name: "TESTING",
    path: PATHS.TESTING,
    component: Simulator,
    withoutCenter: true,
    unAuthorizedProduction: true,
  },
  {
    name: "LIMIT_REACHED",
    path: PATHS.LIMIT_REACHED,
    component: LimitReached,
  },
  {
    name: "SCOPE_CONSENT",
    path: PATHS.CONSENT,
    component: ScopeConsent,
  },
]

// Some routes should be authorized for a specific client like Enrichments for Maestro
export const routeAllowed = (
  path: string,
  clientId?: string | null
): boolean => {
  if (!clientId) {
    return false
  }

  return !!routes.find(
    (route) => route.path === path && route.roles?.includes(clientId)
  )
}
