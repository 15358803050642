import React, { FC } from "react"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import Checkbox from "../inputs/Checkbox"

type Props = {
  name: string
  label: string
  link?: string
  description?: string
}

const FormCheckbox: FC<Props> = ({ name, label, description, link }: Props) => {
  const { t } = useTranslation()
  const { formState, register } = useFormContext()

  return (
    <div className="text-left my-4">
      {formState.errors[name] && (
        <p className="text-urw-red">{t(formState.errors[name].message)}</p>
      )}
      <Checkbox
        label={label}
        link={link}
        description={description}
        {...register(name)}
      />
    </div>
  )
}
export default FormCheckbox
