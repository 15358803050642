import React from "react"
import {
  ParametersStore,
  TParamsStore,
} from "../../utils/store/ParametersStore"
import { Route } from "react-router-dom"
import UnAuthorized from "../../views/UnAuthorized"
import { useStore } from "effector-react"

type TAuthorizedRouteProps = {
  withoutCenter: boolean
  unAuthorizedProduction?: boolean
}
const AuthorizedRoute: React.FC<TAuthorizedRouteProps> = ({
  withoutCenter = false,
  unAuthorizedProduction,
  ...res
}) => {
  const queryParams: TParamsStore = useStore(ParametersStore)
  const displayPage = queryParams.center! && queryParams.client_id!

  return (
    <div>
      {(!unAuthorizedProduction ||
        process.env.REACT_APP_ENV !== "production") &&
      (withoutCenter || displayPage) ? (
        <Route exact {...res} />
      ) : (
        <UnAuthorized />
      )}
    </div>
  )
}

export default AuthorizedRoute
