import { Dispatch, MutableRefObject, SetStateAction, useEffect } from "react"

export const useOutsideAlerter = (
  myButtonRef: MutableRefObject<any>,
  setOpen: Dispatch<SetStateAction<boolean>>,
  type: string,
  ref?: MutableRefObject<any>
): void => {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (myButtonRef.current && !myButtonRef.current.contains(event.target)) {
        if (ref && ref.current && !ref.current.contains(event.target)) {
          setOpen(false)
        }
        if (!ref) {
          setOpen(false)
        }
      }
    }
    // Bind the event listener
    document.addEventListener(type, handleClickOutside)
    return () => {
      // Unbind the event listener on clean up"mousedown"
      document.removeEventListener(type, handleClickOutside)
    }
  }, [setOpen, myButtonRef, type, ref])
}
