import {
  TCenterResponseWS,
  TEnrichmentsRequest,
  TEnrichmentsResponse,
} from "../utils/type/WebServiceType"
import { execute } from "../api/api"
import { API } from "../utils/constantes/ApiRoutes"
import { AxiosResponse } from "axios"

const postEnrichment = (
  body: TEnrichmentsRequest
): Promise<AxiosResponse<TEnrichmentsResponse>> => {
  return execute(API.ENRICHMENT, "POST", body)
}

const getCenter = (
  centerId: string
): Promise<AxiosResponse<TCenterResponseWS>> => {
  return execute(API.CENTER + centerId, "GET")
}

export const othersService = {
  postEnrichment: postEnrichment,
  getCenter: getCenter,
}
