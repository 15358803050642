import React, { FC } from "react"
import ContainerLayout from "../../components/uiComponents/layout/ContainerLayout"
import MainTemplate from "../../components/uiComponents/layout/MainLayout"
import { Controller, FormProvider, useForm } from "react-hook-form"
import FormInput from "../../components/uiComponents/uiForms/FormInput"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { useTranslation } from "react-i18next"
import { PATHS } from "../../utils/constantes/PathRoutes"
import { generateStringQueryParams } from "../../utils/hooks/useQueryParams"
import { useHistory } from "react-router-dom"
import { useStore } from "effector-react"
import { ParametersStore } from "../../utils/store/ParametersStore"
import Button from "../../components/uiComponents/buttons/Button"
import Link from "../../components/uiComponents/buttons/CustomLink"
import ImageCodeValidation from "../../assets/send_code_validation.png"
import { authService } from "../../services/authService"
import { AxiosError, AxiosResponse } from "axios"
import {
  TErrorResponse,
  TRegisterStepResponseWS,
} from "../../utils/type/WebServiceType"
import { TRegisterStep2Request } from "../../utils/type/RegisterType"
import { CenterStore } from "../../utils/store/CenterStore"
import { COOKIE_NAMES } from "../../utils/constantes/Cookies"
import Cookies from "universal-cookie"
import { toast } from "react-toastify"
import { useSetAllCookieUser } from "../../utils/hooks/useCookie"

type TErrorsInputCodeConfirmation = {
  codeValidation: string
}

const schemaValidatorCodeConfirmation = () =>
  yup.object().shape({
    codeValidation: yup.string().required("error.codeRequired"),
  })

const EmailValidationStep2: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const center = useStore(CenterStore)
  const paramsStore = useStore(ParametersStore)
  const cookies = new Cookies()
  const email = cookies.get(COOKIE_NAMES.USER_INFORMATION).email
  const methods = useForm<TErrorsInputCodeConfirmation>({
    resolver: yupResolver(schemaValidatorCodeConfirmation()),
    mode: "onBlur",
  })

  console.log("email", email)

  const resendCodeRequest = () => {
    const body = {
      centerId: center.fid_id,
      email: email || "",
    }
    authService
      .resendCode(body, paramsStore.client_id)
      .then(() => {
        toast.success(t("success.codeValidationResend"))
      })
      .catch((error: AxiosError<TErrorResponse>) => {
        switch (error.response?.data?.error?.code.toString()) {
          case "400":
            toast.warning(t("warning.accountAlreadyExist"))
            history.push({
              pathname: PATHS.LOGIN,
              search: generateStringQueryParams(paramsStore),
            })
            break
          case "500":
          default:
            toast.error(t("error.server"))
        }
        toast.error(t("error.server"))
      })
  }

  const codeConfirmationRequest = (
    data: TErrorsInputCodeConfirmation
  ): void => {
    const finalBody: TRegisterStep2Request = {
      centerId: center.fid_id,
      verificationCode: data.codeValidation,
      email: email || "",
    }

    authService
      .postRegisterStep2(finalBody, paramsStore.client_id)
      .then((response: AxiosResponse<TRegisterStepResponseWS>) => {
        useSetAllCookieUser({
          email: email,
          token: response.data.response.tokens.accessToken,
          isMultistepRegistration: true,
        })
        history.push({
          pathname: PATHS.REGISTER.MAIN,
          search: generateStringQueryParams(paramsStore),
          state: { from: "email" },
        })
      })
      .catch((error: AxiosError<TErrorResponse>) => {
        switch (error.response?.data?.error?.code.toString()) {
          case "400":
            methods.setError(
              "codeValidation",
              { type: "focus", message: `${t("error.codeExpired")}` },
              { shouldFocus: true }
            )
            break
          case "500":
          default:
            toast.error(t("error.server"))
        }
      })
  }

  return (
    <MainTemplate className="space-y-6">
      <ContainerLayout className="flex-col" large>
        <div className="flex flex-col items-center space-y-3 w-1/2 mb-6">
          <div>
            <img
              src={ImageCodeValidation}
              width="400"
              alt="Logo westfield"
              className="object-center"
            />
          </div>
          <div className="mb-6">
            <p className="text-xl font-bold">
              {t("register.emailValidation.step2.title")}
            </p>
            <p className="text-sm -mt-2">
              {t("register.emailValidation.step2.description")}
            </p>
          </div>
        </div>
        <div className="w-full xl:w-1/2 space-y-12">
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(codeConfirmationRequest)}
              className="text-sm flex flex-col items-center xl:w-full"
            >
              <Controller
                render={() => (
                  <FormInput
                    label={t(
                      "register.emailValidation.step2.forms.codeValidation"
                    )}
                    name="codeValidation"
                    type="number"
                    mandatory
                  />
                )}
                name="codeValidation"
              />
            </form>
          </FormProvider>
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(codeConfirmationRequest)}
              className="xl:w-full xl:px-4"
            >
              <div className="flex flex-col space-y-3 items-center mt-6">
                <div className="flex flex-col xl:flex-row space-y-3 xl:space-y-0 xl:space-x-3 items-center">
                  <Button
                    type="button"
                    styleCss="NEUTRAL"
                    onClick={() => {
                      resendCodeRequest()
                    }}
                  >
                    {t("register.emailValidation.step2.buttons.newCode")}
                  </Button>
                  <Button type="submit" styleCss="ACTION">
                    {t("buttons.continue")}
                  </Button>
                </div>
                <Link
                  className="cursor-pointer text-sm"
                  label="register.emailValidation.step2.buttons.wrongEmail"
                  to={{
                    pathname: PATHS.REGISTER.STEP1,
                    search: generateStringQueryParams(paramsStore),
                  }}
                />
              </div>
            </form>
          </FormProvider>
        </div>
      </ContainerLayout>
    </MainTemplate>
  )
}

export default EmailValidationStep2
