import React, {
  DetailedHTMLProps,
  FC,
  ForwardedRef,
  forwardRef,
  ForwardRefRenderFunction,
  InputHTMLAttributes,
} from "react"
import { Trans } from "react-i18next"
import parse from "html-react-parser"

type CheckboxProps = Omit<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  "ref"
> & {
  label: string
  link?: string
  description?: string
}

const CheckboxComponent: ForwardRefRenderFunction<
  HTMLInputElement,
  CheckboxProps
> = (props, ref: ForwardedRef<HTMLInputElement>) => {
  const { label, link, description, ...rest } = props

  return (
    <div className="flex">
      <input
        ref={ref}
        className="form-checkbox focus:outline-none mt-0.75 h-5 w-5 text-gray-600 rounded-sm md:h-3 md:w-3"
        type="checkbox"
        {...rest}
      />
      <div className="ml-2 font-light text-mxs">
        <div className="mb-2">{parse(label)}</div>
        {description && <Trans i18nKey={description}>Temporaire text</Trans>}
      </div>
    </div>
  )
}

const Checkbox: FC<CheckboxProps> = forwardRef<HTMLInputElement, CheckboxProps>(
  CheckboxComponent
)

export default Checkbox
