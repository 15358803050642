import React, { FC, useEffect } from "react"
import { PATHS, routeAllowed } from "../utils/constantes/PathRoutes"
import { authService } from "../services/authService"
import { AxiosResponse } from "axios"
import { useGetValueSearchUrl } from "../utils/hooks/useUrlFunctions"
import { TMagicLinkResponse } from "../utils/type/WebServiceType"
import { ParametersStore, setCenterId } from "../utils/store/ParametersStore"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"
import { generateStringQueryParams } from "../utils/hooks/useQueryParams"
import { useHistory } from "react-router-dom"
import LoaderComponent from "../components/uiComponents/LoaderComponent"
import { useStore } from "effector-react"
import { useSetAllCookieUser } from "../utils/hooks/useCookie"

const AuthMagicLink: FC = () => {
  const { t } = useTranslation()
  const paramsStore = useStore(ParametersStore)
  const history = useHistory()
  const token = useGetValueSearchUrl().get("token")

  useEffect(() => {
    if (token) {
      authService
        .getMagicLink(token)
        .then((response: AxiosResponse<TMagicLinkResponse>) => {
          setCenterId(response.data.centerId)
          if (
            response.status === 200 &&
            routeAllowed(PATHS.ENRICHMENT, paramsStore.client_id) &&
            response.data.firstConnection
          ) {
            useSetAllCookieUser({
              customerId: response.data.customerId,
              email: response.data.email,
              centerId: response.data.centerId,
            })
            history.push({
              pathname: PATHS.ENRICHMENT,
              search: generateStringQueryParams(paramsStore),
            })
          } else {
            history.push({
              pathname: PATHS.AUTH,
              search: generateStringQueryParams(paramsStore),
            })
          }
        })
        .catch(() => {
          toast.error(t("error.server"))
          history.push({
            pathname: PATHS.LOGIN,
            search: generateStringQueryParams(paramsStore),
          })
        })
    } else {
      history.push({
        pathname: PATHS.LOGIN,
        search: generateStringQueryParams(paramsStore),
      })
    }
  }, [])

  return <LoaderComponent />
}
export default AuthMagicLink
