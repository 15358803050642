import React, { FC } from "react"
import GoogleButton from "../uiComponents/buttons/GoogleButton"
import { TSocialInformations } from "../../utils/type/RegisterType"
import { FaCheck } from "react-icons/fa"
import { useTranslation } from "react-i18next"

type TSocialRegistrationProps = {
  callback: (socialInfo?: TSocialInformations) => void
  isAutocompleted: boolean
}

const SocialRegistration: FC<TSocialRegistrationProps> = ({
  callback,
  isAutocompleted,
}: TSocialRegistrationProps) => {
  const { t } = useTranslation()
  return (
    <div
      className={`flex flex-col p-6 rounded shadow-sm space-y-3 items-center mb-12 border border-gray-100 ${
        isAutocompleted ? "bg-green-100" : "bg-white"
      }`}
    >
      {!isAutocompleted && (
        <>
          <div className="mb-3">
            <p className="text-xl leading-none pb-3">
              {t("register.description")}
            </p>
            <p className="text-xs font-light">
              {t("register.socialDescription")}
            </p>
          </div>
          <div className="flex">
            <GoogleButton type="register" callbackRegister={callback} />
          </div>
        </>
      )}
      {isAutocompleted && (
        <>
          <p className="text-xl flex items-center">
            {t("register.socialTitleAfterAutocompleted")}
            <FaCheck className="ml-4 text-lg" />
          </p>
          <p className="font-light">
            {t("register.socialDescriptionAfterAutocompleted")}
          </p>
        </>
      )}
    </div>
  )
}

export default SocialRegistration
