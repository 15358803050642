import React, { FC } from "react"

const NotFound: FC = () => {
  return (
    <div className="flex flex-col justify-center">
      <p className="text-7xl">404</p>
      <p className="text-4xl">PAGE NOT FOUND</p>
    </div>
  )
}
export default NotFound
