import React, { FC } from "react"
import ContainerLayout from "../../components/uiComponents/layout/ContainerLayout"
import MainTemplate from "../../components/uiComponents/layout/MainLayout"
import { Controller, FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import FormInput from "../../components/uiComponents/uiForms/FormInput"
import { useTranslation } from "react-i18next"
import FooterRegister from "../../components/register/FooterRegister"
import SocialRegistration from "../../components/register/SocialRegistration"
import { isUS_UK } from "../../utils/functions/paramsFunctions"
import {
  TRegisterStep1Request,
  TSocialInformations,
} from "../../utils/type/RegisterType"
import { PATHS } from "../../utils/constantes/PathRoutes"
import { generateStringQueryParams } from "../../utils/hooks/useQueryParams"
import { useHistory } from "react-router-dom"
import { useStore } from "effector-react"
import { ParametersStore } from "../../utils/store/ParametersStore"
import { authService } from "../../services/authService"
import { CenterStore } from "../../utils/store/CenterStore"
import { AxiosError } from "axios"
import { TErrorResponse } from "../../utils/type/WebServiceType"
import { toast } from "react-toastify"
import { useSetAllCookieUser } from "../../utils/hooks/useCookie"

interface IErrorsInputRegisterEmailValidation {
  email: string
  password: string
}

const schemaValidatorRegisterEmailValidation = () =>
  yup.object().shape({
    email: yup
      .string()
      .trim()
      .required("error.emailRequired")
      .email("error.emailInvalid"),
    password: yup
      .string()
      .required("error.passwordRequired")
      .min(6, "error.passwordMinLength")
      .max(20, "error.passwordMaxLength"),
  })

const EmailValidationStep1: FC = () => {
  const { t } = useTranslation()
  const paramsStore = useStore(ParametersStore)
  const history = useHistory()
  const center = useStore(CenterStore)
  const methods = useForm<IErrorsInputRegisterEmailValidation>({
    resolver: yupResolver(schemaValidatorRegisterEmailValidation()),
    mode: "onBlur",
  })

  const catchSocialInformations = (socialInfo?: TSocialInformations) => {
    if (!socialInfo) {
      return
    }

    history.push({
      pathname: PATHS.REGISTER.MAIN,
      search: generateStringQueryParams(paramsStore),
      state: { from: "google", socialInformation: socialInfo },
    })
  }

  const sendCodeRequest = (data: IErrorsInputRegisterEmailValidation): void => {
    const finalBody: TRegisterStep1Request = {
      centerId: center.fid_id,
      password: data.password,
      createdFrom: "Partner",
      email: data.email,
    }

    authService
      .postRegisterStep1(finalBody, paramsStore.client_id)
      .then(() => {
        useSetAllCookieUser({
          email: data.email,
        })
        history.push({
          pathname: PATHS.REGISTER.STEP2,
          search: generateStringQueryParams(paramsStore),
        })
      })
      .catch((error: AxiosError<TErrorResponse>) => {
        switch (error.response?.data?.error?.code.toString()) {
          case "E40902":
            toast.warning(t("warning.accountAlreadyExist"))
            useSetAllCookieUser({
              email: data.email,
            })
            history.push({
              pathname: PATHS.LOGIN,
              search: generateStringQueryParams(paramsStore),
            })
            break
          case "409": // if multisteps
            if (
              error.response.data.error.details?.currentRegistrationStep === 1
            ) {
              useSetAllCookieUser({
                email: data.email,
              })
              history.push({
                pathname: PATHS.REGISTER.STEP2,
                search: generateStringQueryParams(paramsStore),
              })
            } else if (
              error.response.data.error.details?.currentRegistrationStep === 2
            ) {
              useSetAllCookieUser({
                email: data.email,
                token: error.response.data.error.response.tokens.accessToken,
                isMultistepRegistration: true,
              })
              history.push({
                pathname: PATHS.REGISTER.MAIN,
                search: generateStringQueryParams(paramsStore),
                state: { from: "email" },
              })
            } else {
              toast.error(t("error.authentication"))
              useSetAllCookieUser({
                email: data.email,
              })
              history.push({
                pathname: PATHS.LOGIN,
                search: generateStringQueryParams(paramsStore),
              })
            }
            break
          default:
            toast.error(t("error.server"))
        }
      })
  }

  return (
    <MainTemplate>
      <ContainerLayout className="flex-col" large>
        <div className="xl:flex items-center xl:w-full xl:px-4 xl:space-x-8">
          <div className="xl:w-2/5 text-urw-gray">
            <SocialRegistration
              callback={catchSocialInformations}
              isAutocompleted={false}
            />
          </div>
          <div className={` ${!isUS_UK() && "xl:w-3/5"}`}>
            <FormProvider {...methods}>
              <form
                onSubmit={methods.handleSubmit(sendCodeRequest)}
                className="text-sm flex flex-col items-center xl:w-full"
              >
                <div className="w-full grid grid-cols-1 xl:grid-cols-2 xl:gap-4">
                  <Controller
                    render={() => (
                      <FormInput
                        label={t("forms.email")}
                        name="email"
                        type="email"
                        mandatory
                      />
                    )}
                    name="email"
                  />
                  <Controller
                    render={() => (
                      <div>
                        <FormInput
                          type="password"
                          name="password"
                          label={t("forms.password")}
                          mandatory
                        />
                        <p className="text-xs text-left">
                          {t("register.passwordAlert")}
                        </p>
                      </div>
                    )}
                    name="password"
                  />
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(sendCodeRequest)}
            className="xl:w-full xl:px-4"
          >
            <FooterRegister acceptTerms={false} />
          </form>
        </FormProvider>
      </ContainerLayout>
    </MainTemplate>
  )
}
export default EmailValidationStep1
