import React, { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import "react-toastify/dist/ReactToastify.css"
import { FormProvider, useForm } from "react-hook-form"
import * as yup from "yup"
import RegisterForm from "../components/register/RegisterForm"
import PrivacyForm from "../components/register/PrivacyForm"
import {
  ParametersStore,
  setCenterId,
  TParamsStore,
} from "../utils/store/ParametersStore"
import SocialRegistration from "../components/register/SocialRegistration"
import {
  THeaderRegisterRequest,
  TRegisterRequest,
  TSocialInformations,
} from "../utils/type/RegisterType"
import { toast } from "react-toastify"
import { authService } from "../services/authService"
import {
  TErrorResponse,
  TRegisterResponseWS,
} from "../utils/type/WebServiceType"
import { useHistory, useLocation } from "react-router-dom"
import { PATHS, routeAllowed } from "../utils/constantes/PathRoutes"
import MainTemplate from "../components/uiComponents/layout/MainLayout"
import ContainerLayout from "../components/uiComponents/layout/ContainerLayout"
import Cookies from "universal-cookie"
import { COOKIE_NAMES, COOKIE_OPTIONS } from "../utils/constantes/Cookies"
import { isDateOldEnough, isDateValid } from "../utils/functions/fieldFunctions"
import { isUS_UK } from "../utils/functions/paramsFunctions"
import { AxiosError, AxiosResponse } from "axios"
import { generateStringQueryParams } from "../utils/hooks/useQueryParams"
import { useStore } from "effector-react"
import { CenterStore } from "../utils/store/CenterStore"
import { yupResolver } from "@hookform/resolvers/yup"
import FooterRegister from "../components/register/FooterRegister"

interface IErrorsInputRegister {
  email: string
  password: string
  firstname: string
  lastname: string
  center: number
  gender: string
  birthdate: string
  privacyPolicy: boolean
  termsOfUse: boolean
}

type TLocation = {
  from: "email" | "facebook" | "google" | "cardRecognition" | "partial"
  socialInformation?: TSocialInformations
}

const schemaValidatorRegister = (
  hasSocialCompleted: boolean,
  isMultistepRegistration: boolean
) =>
  yup.object().shape({
    email: isMultistepRegistration
      ? yup
          .string()
          .trim()
          .required("error.emailRequired")
          .email("error.emailInvalid")
      : yup.string(),
    firstname: yup.string().trim().required("error.firstnameRequired"),
    lastname: yup.string().trim().required("error.lastnameRequired"),
    gender: yup.string().required("error.genderRequired"),
    birthdate: yup.string().required("error.birthdayRequired"),
    termsOfUse: yup.string().required("error.birthdayRequired"),
    password:
      hasSocialCompleted && isMultistepRegistration
        ? yup
            .string()
            .required("error.passwordRequired")
            .min(6, "error.passwordMinLength")
            .max(20, "error.passwordMaxLength")
        : yup.string(),
  })

const Register: FC = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const location = useLocation<TLocation>()
  const center = useStore(CenterStore)
  const queryParams: TParamsStore = useStore(ParametersStore)
  const cookies = new Cookies()
  const cookieUserInformation = cookies.get(COOKIE_NAMES.USER_INFORMATION)
  const from = location.state?.from
  const emailMultistepRegistration = cookieUserInformation?.email || undefined
  const isMultistepRegistration =
    cookieUserInformation?.isMultistepRegistration || false
  const [socialInformations, setSocialInformations] =
    useState<TSocialInformations | null>(
      location.state?.socialInformation || null
    )
  const methods = useForm<IErrorsInputRegister>({
    resolver: yupResolver(
      schemaValidatorRegister(!socialInformations, !isMultistepRegistration)
    ),
    mode: "onBlur",
  })
  const isUSUKVariable = isUS_UK()

  const registerRequest = (data: IErrorsInputRegister): void => {
    const origin = !socialInformations
      ? from
        ? from
        : "email"
      : socialInformations.origin

    if (!isDateValid(data.birthdate) || !isDateOldEnough(data.birthdate)) {
      return
    }

    const generalBody: TRegisterRequest = {
      centerId: center.fid_id,
      email: isMultistepRegistration ? emailMultistepRegistration : data.email,
      firstName: data.firstname,
      lastName: data.lastname,
      gender: data.gender,
      origin: origin,
      birthDate: data.birthdate,
      createdFrom: "Partner",
      optinCommercial: data.privacyPolicy,
      creation_source: "web_signup",
    }

    //TODO trouver mieux pour les newsletters
    const finalBody = {
      ...generalBody,
      ...(!socialInformations ? { password: data.password } : null),
      ...(socialInformations
        ? {
            token: socialInformations.tokenRegister
              ? socialInformations.tokenRegister
              : socialInformations.tokenRegister,
          }
        : null),
      ...(data.privacyPolicy && isUSUKVariable
        ? {
            newsletter_subscriptions: [
              `${center?.center.toLowerCase()}_general`,
            ],
            interest_ids: [],
          }
        : null),
    }

    const headers: THeaderRegisterRequest = {
      ...(isMultistepRegistration
        ? { "x-access-token": cookieUserInformation.token }
        : undefined),
    }

    authService
      .postRegister(
        finalBody,
        headers,
        queryParams.client_id,
        isMultistepRegistration
      )
      .then((response: AxiosResponse<TRegisterResponseWS>) => {
        setCenterId(response.data.centerId)
        if (routeAllowed(PATHS.ENRICHMENT, queryParams.client_id)) {
          cookies.set(
            COOKIE_NAMES.USER_INFORMATION,
            {
              customerId: response.data.customerId,
              email: response.data.email,
              centerId: response.data.centerId,
            },
            COOKIE_OPTIONS
          )
          history.push({
            pathname: PATHS.ENRICHMENT,
            search: generateStringQueryParams(queryParams),
            state: {
              customerId: response.data.customerId,
              email: response.data.email,
              centerId: response.data.centerId,
            },
          })
        } else {
          history.push({
            pathname: PATHS.AUTH,
            search: generateStringQueryParams(queryParams),
          })
        }
      })
      .catch((error: AxiosError<TErrorResponse>) => {
        switch (error.response?.data?.error?.code) {
          case "E40902": // if already exist
            toast.warning(t("warning.accountAlreadyExist"))
            history.push({
              pathname: PATHS.LOGIN,
              search: generateStringQueryParams(queryParams),
              state: {
                email: data.email,
              },
            })
            break
          case "E40905": // if not a physical/partial customer
            toast.error(t("error.emailPhysiqueNotExist"))
            break
          case "E40003":
            toast.error(t("error.centerNotInCountry"))
            break
          default:
            toast.error(t("error.server"))
            break
        }
      })
  }

  const catchSocialInformations = (socialInfo?: TSocialInformations) => {
    if (!socialInfo) {
      return
    }
    // SAVE VALUE SOCIAL INFORMATIONS
    setSocialInformations(socialInfo)
  }

  useEffect(() => {
    // UPDATE FORM
    if (socialInformations) {
      methods.setValue("email", socialInformations.email)
      methods.setValue("firstname", socialInformations.firstname)
      methods.setValue("lastname", socialInformations.lastname)
    }
  }, [socialInformations])

  return (
    <MainTemplate>
      <ContainerLayout className="flex-col" large>
        {isUS_UK() && (
          <p className="text-xl mb-6 p-6">{t("register.description")}</p>
        )}
        {from === "cardRecognition" && (
          <p className="text-white bg-yellow-400 bg-opacity-50 rounded shadow-sm mb-12 p-6">
            {t("register.cardRecognitionInfo")}
          </p>
        )}
        <div className="xl:flex items-center xl:w-full xl:px-4 xl:space-x-8">
          {!isUS_UK() && !isMultistepRegistration && (
            <div className="xl:w-2/5 text-urw-gray">
              <SocialRegistration
                callback={catchSocialInformations}
                isAutocompleted={!!socialInformations}
              />
            </div>
          )}
          <div
            className={` ${
              !isUS_UK() && !isMultistepRegistration && "xl:w-3/5"
            }`}
          >
            <FormProvider {...methods}>
              <form
                onSubmit={methods.handleSubmit(registerRequest)}
                className="text-sm flex flex-col items-center xl:w-full"
              >
                <RegisterForm
                  hasSocialInformation={!!socialInformations}
                  isMultistepRegistration={isMultistepRegistration}
                />
              </form>
            </FormProvider>
          </div>
        </div>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(registerRequest)}
            className="xl:w-full xl:px-4"
          >
            <PrivacyForm />
            <FooterRegister />
          </form>
        </FormProvider>
      </ContainerLayout>
    </MainTemplate>
  )
}

export default Register
