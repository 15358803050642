import React, { FC } from "react"
import { PATHS, routes } from "../../utils/constantes/PathRoutes"
import { TRoute } from "../../utils/type/RouteType"
import { Redirect, Route, Switch } from "react-router-dom"
import NotFound from "../../views/NotFound"
import AuthorizedRoute from "./AuthorizedRoute"
import { useParseUrl } from "../../utils/hooks/useUrlFunctions"

const CustomSwitch: FC = () => {
  useParseUrl()
  return (
    <Switch>
      {routes.map((route: TRoute) => (
        <AuthorizedRoute
          withoutCenter={route.withoutCenter || false}
          unAuthorizedProduction={route.unAuthorizedProduction}
          {...route}
          key={route.name}
        />
      ))}
      <Route exact path={PATHS.HOME}>
        <Redirect to={PATHS.LOGIN} />
      </Route>
      <Route component={NotFound} />
    </Switch>
  )
}
export default CustomSwitch
