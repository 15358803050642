import React, { FC } from "react"

export const ButtonType = {
  CANCEL: "bg-gray-medium text-text-gray p-2",
  ACTION: "bg-urw-black text-white hover:bg-urw-gray p-2",
  ERROR: "bg-red-500 text-white p-2",
  WARNING: "bg-yellow-500 p-2 ",
  SECONDARY: "bg-urw-gray text-white hover:bg-urw-gray-light p-2",
  NEUTRAL: "border border-urw-gray-light p-2",
  TRANSPARENT: "text-gray-500 underline",
  UNDERLINE: "underline",
}

type TButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  styleCss?: keyof typeof ButtonType
  large?: boolean
  small?: boolean
  className?: string
  fixedSize?: string
}

const Button: FC<TButtonProps> = ({
  styleCss,
  large = false,
  small = false,
  fixedSize,
  children,
  className,
  ...rest
}: TButtonProps) => {
  return (
    <button
      className={`${styleCss && ButtonType[styleCss]} ${
        large ? "w-full" : small ? "" : fixedSize ? fixedSize : "w-64"
      } focus:outline-none rounded text-sm disabled:bg-gray-medium disabled:text-text-gray disabled:cursor-default${className}`}
      {...rest}
    >
      {children}
    </button>
  )
}
export default Button
