import React, { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import FormCheckbox from "../uiComponents/uiForms/FormCheckbox"
import { LEGAL_TEXT_LABEL } from "../../utils/constantes/Centers"
import { isUS_UK } from "../../utils/functions/paramsFunctions"
import { useStore } from "effector-react"
import { CenterStore } from "../../utils/store/CenterStore"
import parse from "html-react-parser"
import { ParametersStore } from "../../utils/store/ParametersStore"

type Language = {
  default: string
  [key: string]: string
}

const PrivacyForm: FC = () => {
  const { t } = useTranslation()
  const center = useStore(CenterStore)
  const currentLanguage = useStore(ParametersStore).lang
  const [privacyPolicy, setPrivacyPolicyText] = useState("")
  const [termsOfUse, setTermsOfUse] = useState("")
  const [termsOfUseDetails, setTermsOfUseDetails] = useState("")

  const getCurrentTranslation = (
    tabTranslation: Language,
    currentLanguage: string
  ): string => {
    let currentTranslation = ""
    Object.entries(tabTranslation).map(([key, value]) => {
      if (key === currentLanguage) currentTranslation = value
    })
    return currentTranslation
  }

  useEffect(() => {
    const keyLanguage = currentLanguage ? currentLanguage : "default"

    const privacyPolicyTemp = center?.legalText.find((legalText) => {
      return legalText.label === LEGAL_TEXT_LABEL.PRIVACY_POLICY
    })

    const termsOfUseTemp = center?.legalText.find((legalText) => {
      return legalText.label === LEGAL_TEXT_LABEL.TERMS_OF_USE_P1
    })

    const termsOfUseDetailsTemp = center?.legalText.find((legalText) => {
      return legalText.label === LEGAL_TEXT_LABEL.TERMS_OF_USE_P2
    })

    if (privacyPolicyTemp && privacyPolicyTemp.content) {
      try {
        setPrivacyPolicyText(
          getCurrentTranslation(
            JSON.parse(privacyPolicyTemp.content),
            keyLanguage
          )
        )
      } catch (e: any) {
        setPrivacyPolicyText("")
      }
    }

    if (termsOfUseDetailsTemp && termsOfUseDetailsTemp.content) {
      try {
        setTermsOfUseDetails(
          getCurrentTranslation(
            JSON.parse(termsOfUseDetailsTemp.content),
            keyLanguage
          )
        )
      } catch (e: any) {
        setTermsOfUseDetails("")
      }
    }

    if (termsOfUseTemp && termsOfUseTemp.content) {
      try {
        setTermsOfUse(
          getCurrentTranslation(JSON.parse(termsOfUseTemp.content), keyLanguage)
        )
      } catch (e: any) {
        setTermsOfUse("")
      }
    }
  }, [center, currentLanguage])

  return (
    <div className="py-6">
      {(isUS_UK() || center) && (
        <>
          <p className="text-xl text-left">
            {t("register.titleNewsletter").toUpperCase()}
          </p>
          <FormCheckbox
            name="privacyPolicy"
            label={privacyPolicy}
            link={center?.url_privacy_policy}
          />
          <FormCheckbox
            label={termsOfUse}
            name="termsOfUse"
            link={center?.url_terms_of_use}
          />
          <div className="ml-5 text-left font-light text-mxs cursor-default">
            {center && parse(termsOfUseDetails)}
          </div>
        </>
      )}
    </div>
  )
}

export default PrivacyForm
