import { CookieSetOptions } from "universal-cookie"

export const COOKIE_NAMES = {
  USER_INFORMATION: "URW-User",
}

export const COOKIE_OPTIONS: CookieSetOptions = {
  path: "/",
  secure: true,
  sameSite: "strict",
  httpOnly: false, // as we can only have 1 httpOnly cookie for a given domain
  maxAge: 30 * 60,
}
