import React, { FC, useState } from "react"
import { useTranslation } from "react-i18next"
import { enrichmentValue } from "../utils/constantes/Enrichments"
import Chip from "../components/uiComponents/Chips"
import Button from "../components/uiComponents/buttons/Button"
import MainTemplate from "../components/uiComponents/layout/MainLayout"
import { TEnrichmentsRequest, TSegment } from "../utils/type/WebServiceType"
import { othersService } from "../services/othersService"
import { toast } from "react-toastify"
import ContainerLayout from "../components/uiComponents/layout/ContainerLayout"
import Cookies from "universal-cookie"
import { COOKIE_NAMES } from "../utils/constantes/Cookies"
import { PATHS } from "../utils/constantes/PathRoutes"
import { useHistory } from "react-router-dom"
import { generateStringQueryParams } from "../utils/hooks/useQueryParams"
import { useStore } from "effector-react"
import { ParametersStore } from "../utils/store/ParametersStore"

const Enrichment: FC = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const params = useStore(ParametersStore)
  const [listValueEnrichment] = useState<string[]>([])
  const cookies = new Cookies()
  const cookieUserInformation = cookies.get(COOKIE_NAMES.USER_INFORMATION)

  const handleChangeValueEnrichment = (key: string, active: boolean) => {
    if (active) {
      listValueEnrichment.push(key)
    } else {
      const indexElement = listValueEnrichment.findIndex(
        (element) => element === key
      )
      if (indexElement === -1) {
        return
      }
      listValueEnrichment.splice(indexElement, 1)
    }
  }

  const generateListSegment = (): TSegment[] => {
    if (listValueEnrichment.length === 0) return []

    return listValueEnrichment.map((segmentKey) => {
      const segmentElement = enrichmentValue.find(
        (element) => element.key === segmentKey
      )

      const segment: TSegment = {
        segmentId: segmentElement?.id || "",
        segmentName: t(segmentElement?.label || ""),
      }
      return segment
    })
  }

  const saveEnrichment = () => {
    const body: TEnrichmentsRequest = {
      centerId: cookieUserInformation.centerId,
      customerId: cookieUserInformation.customerId,
      email: cookieUserInformation.email,
      segments: generateListSegment(),
    }

    if (generateListSegment().length === 0) {
      history.push({
        pathname: PATHS.AUTH,
        search: generateStringQueryParams(params),
      })
    } else {
      othersService
        .postEnrichment(body)
        .then(() => {
          history.push({
            pathname: PATHS.AUTH,
            search: generateStringQueryParams(params),
          })
        })
        .catch(() => toast.error(t("error.server")))
    }
  }

  return (
    <MainTemplate>
      <ContainerLayout className="flex flex-col items-center" large>
        <div className="p-4 space-y-1 items-center mb-2">
          <p className="text-xl">{t("enrichment.title")}</p>
          <p>{t("enrichment.description")}</p>
        </div>
        <div className="flex justify-center flex-wrap mb-10 text-sm">
          {enrichmentValue.map((enrichment) => (
            <Chip
              label={enrichment.label}
              key={enrichment.key}
              isSelected={false}
              onChange={(newValue) =>
                handleChangeValueEnrichment(enrichment.key, newValue)
              }
            />
          ))}
        </div>
        <Button type="button" styleCss="ACTION" onClick={saveEnrichment}>
          {t("buttons.continueConcert")}
        </Button>
      </ContainerLayout>
    </MainTemplate>
  )
}
export default Enrichment
