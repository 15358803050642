import { useEffect } from "react"
import { setParamsInfo } from "../store/ParametersStore"
import { useLocation } from "react-router-dom"
import { TCenter } from "../constantes/Centers"
import { centers } from "../../fakeApiGeneralization/Centers"
import { setCenterStore } from "../store/CenterStore"
import { othersService } from "../../services/othersService"
import { AxiosResponse } from "axios"
import { TCenterResponseWS } from "../type/WebServiceType"

export const useGetValueSearchUrl = (): URLSearchParams => {
  const location = useLocation()
  return new URLSearchParams(location.search)
}

export const useParseUrl = (): void => {
  const centerId = useGetValueSearchUrl().get("center")
  const urlRedirection = useGetValueSearchUrl().get("redirect_uri")
  const clientId = useGetValueSearchUrl().get("client_id")
  const responseType = useGetValueSearchUrl().get("response_type")
  const state = useGetValueSearchUrl().get("state")
  const scope = useGetValueSearchUrl().get("scope")
  const nonce = useGetValueSearchUrl().get("nonce")
  const consent = useGetValueSearchUrl().get("consent")
  const lang = useGetValueSearchUrl().get("lang")

  useEffect(() => {
    // IF we are everywhere except simulator page
    if (centerId) {
      let centerDistant
      othersService
        .getCenter(centerId)
        .then((response: AxiosResponse<TCenterResponseWS>) => {
          centerDistant = response.data
        })
        .finally(() => {
          const center: TCenter | undefined = centers.find((center) => {
            return (
              center.fid_id.toString() === centerId.toString() ||
              center.center.toString() === centerId.toString()
            )
          })

          const mergeCenter: TCenter = {
            ...center!,
            multistepRegistration:
              centerDistant && centerDistant.multistepRegistration
                ? centerDistant.multistepRegistration
                : false,
          }

          setCenterStore(mergeCenter)
        })
    }

    setParamsInfo({
      center: centerId!,
      client_id: clientId ? clientId : undefined,
      redirect_uri:
        urlRedirection === "undefined" || !urlRedirection
          ? undefined
          : urlRedirection,
      response_type: responseType!,
      scope: scope!,
      state: state!,
      nonce: nonce!,
      lang: lang!,
      consent: consent!,
    })
  }, [centerId, clientId, consent])
}
