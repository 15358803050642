import React, { FC, useEffect, useState } from "react"
import Selector from "../components/uiComponents/inputs/Selector"
import { TOptionItems } from "../components/uiComponents/uiForms/FormSelector"
import Button from "../components/uiComponents/buttons/Button"
import { TCenter } from "../utils/constantes/Centers"
import { useHistory } from "react-router-dom"
import Toggle from "../components/uiComponents/buttons/Toggle"
import LogoWestfield from "../assets/logo_westfield.svg"
import { centers } from "../fakeApiGeneralization/Centers"

//TODO - 02/09/2021 CC - Mettre un mot de passe pour la prod ?
const Simulator: FC = () => {
  const history = useHistory()
  const [centersList, setCenters] = useState<TOptionItems[]>([])
  const [centerValue, setCenterValue] = useState<string>()
  const [centerSelected, setCenterSelected] = useState<TCenter>()
  const [sendFidID, setSendFidId] = useState<boolean>(true)
  const clientID = process.env.REACT_APP_MAESTRO_ID
  const urlRedirection = "https://info.maestro.io"

  useEffect(() => {
    const listCenterSelector: TOptionItems[] = centers
      .map((center: TCenter) => ({
        label: center.center_name,
        key: center.fid_id,
      }))
      .sort((a, b) => a.label.localeCompare(b.label))

    setCenters(listCenterSelector)
  }, [])

  useEffect(() => {
    const centerLocal = centers.find(
      (center) => center.fid_id.toString() === centerValue?.toString()
    )

    setCenterSelected(centerLocal)
  }, [centerValue])

  const simulate = (withoutCenter?: boolean) => {
    history.push({
      pathname: "/auth",
      search: `?redirect_uri=${urlRedirection.toString()}&client_id=${clientID}&response_type=code&scope=profile&nonce=123abc&state=abc123${
        withoutCenter || !centerSelected
          ? ""
          : `&center=${sendFidID ? centerValue : centerSelected?.center}`
      }`,
      state: {},
    })
  }

  return (
    <>
      <div className="flex items-center justify-center p-4 bg-urw-black">
        <img src={LogoWestfield} width="130" alt="Logo westfield" />
      </div>
      <div className="h-screen p-6 flex flex-col items-center space-y-6">
        <div className="md:w-1/3 flex-col text-left space-y-10 border border-gray-300 p-6 rounded">
          <p className=" text-center text-lg">
            Welcome to the simulator. You need to wait 2min between each test or
            you have to delete your cookies between to tests
          </p>
          <div className="w-4/5 space-y-3 flex flex-col items-center w-full">
            <div className="w-full">
              <div className="w-full flex items-center mb-2">
                <p>Select a center</p>
              </div>
              <Selector
                options={centersList}
                onChange={(event) => {
                  setCenterValue(event.currentTarget.value)
                }}
              />
            </div>
          </div>

          <div className="flex items-center space-x-3 justify-center">
            <Button styleCss="SECONDARY" onClick={() => simulate()}>
              URW Connect
            </Button>
          </div>
        </div>
        <div className="w-full md:w-1/3 flex-col align-center space-y-3 p-6 rounded border border-gray-300">
          <p className="mb-2 text-3xl">Technical Part</p>
          <Toggle
            label="Send Fid ID (technical tests)"
            onChange={(value) => setSendFidId(value)}
          />
          <Button large styleCss="SECONDARY" onClick={() => simulate(true)}>
            No center
          </Button>
        </div>
      </div>
    </>
  )
}
export default Simulator
