import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import GoogleButton from "../components/uiComponents/buttons/GoogleButton"
import EmailPasswordForm from "../components/login/EmailPasswordForm"
import { ParametersStore, setCenterId } from "../utils/store/ParametersStore"
import {
  TEmailLogin,
  TFacebookLogin,
  TGoogleLogin,
} from "../utils/type/LoginType"
import { authService } from "../services/authService"
import {
  TConnectionResponseWS,
  TErrorResponse,
} from "../utils/type/WebServiceType"
import { PATHS, routeAllowed } from "../utils/constantes/PathRoutes"
import { toast } from "react-toastify"
import MainTemplate from "../components/uiComponents/layout/MainLayout"
import { isUS_UK } from "../utils/functions/paramsFunctions"
import Button from "../components/uiComponents/buttons/Button"
import ContainerLayout from "../components/uiComponents/layout/ContainerLayout"
import { AxiosResponse, AxiosError } from "axios"
import { generateStringQueryParams } from "../utils/hooks/useQueryParams"
import { useHistory } from "react-router-dom"
import { useStore } from "effector-react"
import { CenterStore } from "../utils/store/CenterStore"
import { useSetAllCookieUser } from "../utils/hooks/useCookie"

const Login: FC = () => {
  const { t } = useTranslation()
  const paramsStore = useStore(ParametersStore)
  const history = useHistory()
  const center = useStore(CenterStore)

  const loginRequest = (
    bodyObject: TEmailLogin | TGoogleLogin | TFacebookLogin,
    errorAction?: () => void
  ) => {
    authService
      .postLogin(bodyObject)
      .then((response: AxiosResponse<TConnectionResponseWS>) => {
        setCenterId(response.data.centerId)
        if (response.status === 204) {
          history.push({
            pathname: PATHS.MAGIC_LINK.MAIN,
            search: generateStringQueryParams(paramsStore),
            state: {
              email: bodyObject.email,
            },
          })
          return
        }
        if (
          response.status === 200 &&
          routeAllowed(PATHS.ENRICHMENT, paramsStore.client_id) &&
          response.data.firstConnection
        ) {
          useSetAllCookieUser({
            customerId: response.data.customerId,
            email: response.data.email,
            centerId: response.data.centerId,
          })
          history.push({
            pathname: PATHS.ENRICHMENT,
            search: generateStringQueryParams(paramsStore),
          })
        } else {
          history.push({
            pathname: PATHS.AUTH,
            search: generateStringQueryParams(paramsStore),
          })
        }
      })
      .catch((error: AxiosError<TErrorResponse>) => {
        console.log(`data: ${JSON.stringify(error.response?.data)}`)
        switch (error.response?.data?.error?.code) {
          case "409": // if multisteps
            if (
              error.response.data.error.details?.currentRegistrationStep === 1
            ) {
              useSetAllCookieUser({
                email: bodyObject.email,
              })
              history.push({
                pathname: PATHS.REGISTER.STEP2,
                search: generateStringQueryParams(paramsStore),
              })
            } else if (
              error.response.data.error.details?.currentRegistrationStep === 2
            ) {
              useSetAllCookieUser({
                email: bodyObject.email,
                token: error.response.data.error.response.tokens.accessToken,
                isMultistepRegistration: true,
              })
              history.push({
                pathname: PATHS.REGISTER.MAIN,
                search: generateStringQueryParams(paramsStore),
                state: { from: "email" },
              })
            } else {
              errorAction && errorAction()
              toast.error(t("error.authentication"))
            }
            break
          case "E40905": // if not a physical/partial customer
            toast.error(t("error.emailPhysiqueNotExist"))
            break
          case "E40101":
            errorAction && errorAction()
            toast.error(t("error.authentication"))
            break
          default:
            toast.error(t("error.server"))
            break
        }
      })
  }

  return (
    <MainTemplate className="space-y-6">
      <ContainerLayout className="flex flex-col items-center">
        {!isUS_UK() && (
          <div className="space-y-3 w-full flex flex-col items-center">
            <GoogleButton type="login" callbackLogin={loginRequest} />
            <div className="flex items-center w-4/5 py-6">
              <p className="h-px w-full bg-white" />
              <p className="px-4 text-center ">
                {t("common.or").toLowerCase()}
              </p>
              <p className="h-px w-full bg-white" />
            </div>
          </div>
        )}
        <EmailPasswordForm callback={loginRequest} />
      </ContainerLayout>
      <ContainerLayout className="flex flex-col items-center space-y-3">
        <Button
          type="button"
          styleCss="ACTION"
          onClick={() =>
            history.push({
              pathname: center.multistepRegistration
                ? PATHS.REGISTER.STEP1
                : PATHS.REGISTER.MAIN,
              search: generateStringQueryParams(paramsStore),
              state: { from: "email" },
            })
          }
          fixedSize="w-2/3"
        >
          {t("buttons.notAccount")}
        </Button>
        {isUS_UK() ? (
          <Button
            type="button"
            small
            styleCss="UNDERLINE"
            onClick={() =>
              history.push({
                pathname: PATHS.REGISTER.MAIN,
                search: generateStringQueryParams(paramsStore),
                state: { from: "partial" },
              })
            }
          >
            {t("buttons.partialCustomer")}
          </Button>
        ) : null}
      </ContainerLayout>
    </MainTemplate>
  )
}
export default Login
