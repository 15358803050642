import React, { FC, useEffect, useState } from "react"
import { PATHS } from "../utils/constantes/PathRoutes"
import { AxiosResponse } from "axios"
import { authService } from "../services/authService"

import { useHistory } from "react-router-dom"
import LoaderComponent from "../components/uiComponents/LoaderComponent"
import { useStore } from "effector-react"
import { ParametersStore } from "../utils/store/ParametersStore"
import { generateStringQueryParams } from "../utils/hooks/useQueryParams"
import { decodeUrl } from "../utils/functions/fieldFunctions"
import { useDeleteCookieUser } from "../utils/hooks/useCookie"

const Auth: FC = () => {
  const params = useStore(ParametersStore)
  const history = useHistory()
  const queryParams = { ...params }
  const [alreadyLaunch, setAlreadyLaunch] = useState<boolean>(false)

  useEffect(() => {
    // Checks that we have at least one query param
    if (Object.values(queryParams).some((value) => !!value) && !alreadyLaunch) {
      setAlreadyLaunch(true)
      useDeleteCookieUser()
      authService
        .authorize(queryParams)
        .then((response: AxiosResponse) => {
          if (response.status === 200) {
            if (
              response.data.redirectUrl &&
              !response.data.redirectUrl.includes("/undefined")
            ) {
              window.location.href = decodeUrl(response.data.redirectUrl)
            } else {
              history.push({
                pathname: PATHS.LOGIN,
                search: generateStringQueryParams(params),
              })
            }
          } else {
            history.push({
              pathname: PATHS.LOGIN,
              search: generateStringQueryParams(params),
            })
          }
        })
        .catch(() => {
          history.push({
            pathname: PATHS.LOGIN,
            search: generateStringQueryParams(params),
          })
        })
        .finally(() => setAlreadyLaunch(false))
    }
  }, [params])

  return <LoaderComponent />
}
export default Auth
