import { createEvent, createStore } from "effector"
import { TCenter } from "../constantes/Centers"

export const CenterInitial: TCenter = {
  center: "",
  center_name: "",
  center_short_name: "",
  country: "",
  default_language: "",
  facebook_id: "",
  fid_id: 0,
  google_id: "",
  is_westfield: false,
  languages_available: [],
  legalText: [],
  url_logo: "",
  url_privacy_policy: "",
  url_terms_of_use: "",
}

export const setCenterStore = createEvent<TCenter | undefined>(
  "SET_CENTERS_INFO"
)
export const clearCenterStore = createEvent("RESET_STORE")

export const CenterStore = createStore<TCenter>(CenterInitial)
  .on(setCenterStore, (state: TCenter, payload: TCenter | undefined) => {
    return payload
  })
  .reset(clearCenterStore)
