const dateMaxBirthday = (): Date => {
  const dateBirthday = new Date()
  dateBirthday.setFullYear(dateBirthday.getFullYear() - 16)
  dateBirthday.setDate(dateBirthday.getDate())
  return dateBirthday
}

export const formatDate = (date: Date): string => {
  // Formating the date in a string like YYYY-mm-dd
  const returnDate = `${date.getFullYear()}-${
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  }-${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}`
  return returnDate
}

export const isDateOldEnough = (date: string): boolean => {
  const [year, month, day] = date
    .split("-")
    .map((element) => parseInt(element, 10))
  const currentDate = new Date(year, month - 1, day)
  const maxDate = dateMaxBirthday()
  return currentDate <= maxDate
}

export const isDateValid = (date: string): boolean => {
  const currentDate = date.split("-")
  if (
    currentDate[0].length < 4 ||
    currentDate[1].length < 2 ||
    currentDate[2].length < 2
  )
    return false
  const [year, month, day] = currentDate.map((element) => parseInt(element, 10))
  if (year < dateMaxBirthday().getFullYear() - 100) return false
  if (day < 1 || day > 31) return false
  if (month < 1 || month > 12) return false

  let nbdays: number
  if (month === 2) {
    nbdays = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0 ? 29 : 28
  } else if (month < 8) {
    nbdays = month % 2 === 0 ? 30 : 31
  } else {
    nbdays = month % 2 === 0 ? 31 : 30
  }

  return day <= nbdays
}

export const decodeUrl = (url: string): string => {
  return url.replace("%2F%2F", "//")
}
