type TEnrichment = {
  key: string
  label: string
  id: string
}

export const enrichmentValue: TEnrichment[] = [
  {
    label: "enrichment.category.women-fashion",
    key: "women_fashion",
    id: "0F584725-33C3-4C83-8238-60E38AB8744A",
  },
  {
    label: "enrichment.category.men-fashion",
    key: "men_fashion",
    id: "F19A6A76-29E5-45FA-A69C-7EAECF7C2D6F",
  },
  {
    label: "enrichment.category.sport",
    key: "sport",
    id: "3109A5D8-85B1-4B81-B349-E1C8DB7E1344",
  },
  {
    label: "enrichment.category.culture",
    key: "culture_multimedia",
    id: "6126B64D-9DD7-4472-A8CC-FF84AA5253F4",
  },
  {
    label: "enrichment.category.food",
    key: "food_hypermarket",
    id: "4EA382F0-A836-478D-88BF-BCE166F72493",
  },
  {
    label: "enrichment.category.restaurants",
    key: "restaurants",
    id: "D4D55B82-029B-4860-A6F1-17E3049FD0A8",
  },
  {
    label: "enrichment.category.beauty",
    key: "beauty_health",
    id: "CA82318E-6B54-40F8-A7C1-36BC4DDFF623",
  },
  {
    label: "enrichment.category.family",
    key: "family",
    id: "D82FE00F-F3E2-4659-BF19-E47C096BD8A2",
  },
  {
    label: "enrichment.category.cinema",
    key: "cinema",
    id: "FC3DFF1D-A304-4D1C-BB1C-8C8DCAC10ADD",
  },
]
