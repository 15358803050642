import React, { FC, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  isDateOldEnough,
  isDateValid,
} from "../../../utils/functions/fieldFunctions"
import { useFormContext } from "react-hook-form"
import { COUNTRIES } from "../../../utils/constantes/Centers"
import { useStore } from "effector-react"
import { CenterStore } from "../../../utils/store/CenterStore"

type DatePickerProps = {
  onChange: (value: string) => void
  label: string
}

type TBirthDate = {
  day?: string
  month?: string
  year?: string
}

const birthDateInitial = {
  day: "",
  month: "",
  year: "",
}

const DatePicker: FC<DatePickerProps> = (props) => {
  const { label, onChange } = props
  const { t } = useTranslation()
  const [birthDate, setBirthDate] = useState<TBirthDate>(birthDateInitial)
  const [error, setError] = useState<string>("")
  const { formState } = useFormContext()
  const center = useStore(CenterStore)
  const isUS =
    center.country.toLowerCase() === COUNTRIES.UNITED_STATE.toLowerCase()
  const dayRef = useRef<any>()
  const monthRef = useRef<any>()
  const yearRef = useRef<any>()

  const verifyDate = (day?: string, month?: string, year?: string) => {
    day = day?.trim()
    month = month?.trim()
    year = year?.trim()
    onChange(`${year}-${month}-${day}`)
    if (
      day?.length !== 2 ||
      month?.length !== 2 ||
      year?.length !== 4 ||
      !isDateValid(`${year}-${month}-${day}`)
    ) {
      setError(t("error.birthdayRequired"))
      return
    }

    if (!isDateOldEnough(`${year}-${month}-${day}`)) {
      setError(t("error.birthdayUnderLegal"))
      return
    }

    setError("")
  }

  const changeFocus = (e: any) => {
    const { value, name } = e.currentTarget

    // change focus to the next field of date picker if current field has two caracters and is not year field
    if (value.length === 2 && name !== "year") {
      let inputNext
      if (isUS) {
        inputNext = name === "month" ? dayRef : yearRef
      } else {
        inputNext = name === "day" ? monthRef : yearRef
      }
      inputNext.current.focus()
    } else if (value.length > 2 && name !== "year") {
      // if field is longer than 2 caracters and isn't year it's an error
      setError(t("error.birthdayRequired"))
    }
    // computing values in form
    setBirthDate((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const looseFocusDay = (e: any) => {
    const { name } = e.currentTarget
    let newValue = e.currentTarget.value.trim()

    if (name === "year") {
      verifyDate(birthDate?.day, birthDate?.month, birthDate?.year)
    } else if (newValue.length === 1) {
      newValue = `0${newValue}`
      setBirthDate((prevState) => ({
        ...prevState,
        [name]: newValue,
      }))
    } else if (newValue.length !== 2) {
      verifyDate(birthDate?.day, birthDate?.month, birthDate?.year)
    }
  }

  useEffect(() => {
    if (
      birthDate?.day?.length === 2 &&
      birthDate?.month?.length === 2 &&
      birthDate?.year?.length === 4
    ) {
      verifyDate(birthDate?.day, birthDate?.month, birthDate?.year)
    }
  }, [birthDate])

  useEffect(() => {
    setError(t(formState.errors.birthdate?.message))
  }, [formState.errors.birthdate])

  return (
    <div className="my-2">
      <p className="text-left mb-2">{label} *</p>
      <div className="text-left rounded w-full focus:outline-none focus:shadow-md">
        <div className="flex text-urw-gray">
          <input
            ref={dayRef}
            className={`${
              isUS ? " order-2" : ""
            } mr-2 border border-1 border-urw-gray-light p-2 rounded w-full focus:outline-none focus:shadow-md`}
            type="number"
            name="day"
            value={birthDate.day}
            placeholder={t("forms.date.days")}
            onChange={(event) => {
              changeFocus(event)
            }}
            onBlur={looseFocusDay}
          />
          <input
            ref={monthRef}
            className={`${
              isUS ? " order-1" : ""
            } mr-2 border border-1 border-urw-gray-light p-2 rounded w-full focus:outline-none focus:shadow-md`}
            type="number"
            name="month"
            value={birthDate.month}
            placeholder={t("forms.date.months")}
            onChange={(event) => {
              changeFocus(event)
            }}
            onBlur={(event) => looseFocusDay(event)}
          />
          <input
            ref={yearRef}
            className="order-3 border border-1 border-urw-gray-light p-2 rounded w-full focus:outline-none focus:shadow-md"
            type="number"
            name="year"
            value={birthDate.year}
            placeholder={t("forms.date.years")}
            onChange={(event) => {
              changeFocus(event)
            }}
            onBlur={(event) => looseFocusDay(event)}
          />
        </div>
        {error && (
          <p className="p-1 bg-urw-red rounded bg-opacity-80 h-full my-1">
            {error}
          </p>
        )}
      </div>
    </div>
  )
}

export default DatePicker
