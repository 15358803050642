import React, { FC, useEffect } from "react"
import FormInput from "../components/uiComponents/uiForms/FormInput"
import Button from "../components/uiComponents/buttons/Button"
import { useTranslation } from "react-i18next"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import { authService } from "../services/authService"
import MainTemplate from "../components/uiComponents/layout/MainLayout"
import { isUS_UK } from "../utils/functions/paramsFunctions"
import ContainerLayout from "../components/uiComponents/layout/ContainerLayout"
import { generateObjectQueryParams } from "../utils/hooks/useQueryParams"
import {
  TErrorResponse,
  TForgotPasswordRequest,
} from "../utils/type/WebServiceType"
import { AxiosError } from "axios"
import { ParametersStore, setCenterId } from "../utils/store/ParametersStore"
import { useStore } from "effector-react"

type TForgotPasswordForm = {
  email: string
  center: string
}

const schemaValidatorForgotPassword = (isUSUK: boolean) =>
  yup.object().shape({
    email: yup
      .string()
      .required("error.emailRequired")
      .email("error.emailInvalid"),
    center: !isUSUK
      ? yup.string().required("error.centerRequired")
      : yup.string(),
  })

const ForgotPassword: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const paramsStore = useStore(ParametersStore)
  const isUSUK = isUS_UK()
  const methods = useForm<TForgotPasswordForm>({
    resolver: yupResolver(schemaValidatorForgotPassword(isUS_UK())),
    mode: "onBlur",
  })

  useEffect(() => {
    if (paramsStore.center) {
      methods.setValue("center", `${paramsStore.center}`)
    }
  }, [])

  const saveCenterIdSelected = (centerId: string) => {
    setCenterId(centerId)
  }

  const onSubmit = (data: TForgotPasswordForm) => {
    const postBody: TForgotPasswordRequest = {
      email: data.email,
      centerId: data.center,
      ...generateObjectQueryParams(paramsStore, ["center"]),
    }

    authService
      .postForgotPassword(postBody)
      .then(() => {
        toast.success(t("success.forgotPassword"))
        saveCenterIdSelected(data.center)
        history.goBack()
      })
      .catch((error: AxiosError<TErrorResponse>) => {
        const socialAccount = !error.response?.data?.error?.details
          ? null
          : error.response?.data?.error?.details?.origins[0] === "google"
          ? "Google +"
          : "Facebook"

        switch (error.response?.data?.error?.code) {
          case "E40904": // if email doesnt exist
            toast.error(t("error.emailNotExist"))
            break
          case "E40901": // if is a social account
            toast.error(
              t("error.emailIsSocial", {
                social: socialAccount,
              })
            )
            break
          default:
            toast.error(t("error.server"))
        }
      })
  }

  return (
    <MainTemplate>
      <ContainerLayout className="flex-col">
        <div className="p-4 space-y-1 items-center mb-2 ">
          <p className="text-3xl">{t("forgotPassword.title")}</p>
          <p>{t("forgotPassword.description")}</p>
        </div>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            className="flex flex-col space-y-3 w-full"
          >
            <div className="grid gap-x-16 gap-y-4">
              <FormInput
                name="email"
                label={t("forms.email")}
                autoFocus
                mandatory
              />
              <Button type="submit" styleCss="ACTION" large>
                {t("buttons.validate")}
              </Button>
            </div>
          </form>
        </FormProvider>
      </ContainerLayout>
    </MainTemplate>
  )
}
export default ForgotPassword
