import Cookies from "universal-cookie"
import { COOKIE_NAMES, COOKIE_OPTIONS } from "../constantes/Cookies"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useSetAllCookieUser = (cookiesInformation: any): void => {
  const cookies = new Cookies()

  cookies.set(COOKIE_NAMES.USER_INFORMATION, cookiesInformation, COOKIE_OPTIONS)
}

export const useDeleteCookieUser = (): void => {
  const cookies = new Cookies()
  cookies.remove(COOKIE_NAMES.USER_INFORMATION)
}
